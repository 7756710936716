import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import SiteContext from "../../context/siteContext"

import { makeStyles, Badge, useMediaQuery } from "@material-ui/core"

import "../layout.css"

import CartItems from "../../../static/images/Icons/cartItems.svg"

const useStyle = makeStyles(theme => ({
  headerIcons: {
    color: "#fff",
    padding: "0.1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
      width: "1.3rem",
    },
  },
  cartBadge: {
    "& .MuiBadge-badge": {
      minWidth: "12px",
      height: "12px",
      padding: "10px 7px",
      fontWeight: "bold",
    },
  },
  headerIconsCartMobile: {
    width: "1.6rem",
    height: "1.6rem",
  },
}))

function CartItemsBadge({ isDisplay = false }) {
  const classes = useStyle()
  const { t } = useTranslation()
  const { cartItemContentLenght } = useContext(SiteContext)

  const isMobile = useMediaQuery("(max-width:959.95px)")

  return (
    <>
      {isDisplay && (
        <Badge
          badgeContent={cartItemContentLenght}
          color="secondary"
          className={classes.cartBadge}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <img
            src={CartItems}
            alt={t("header.cartItems")}
            className={`${classes.headerIcons} ${classes.headerIconsLang} ${
              !isMobile && classes.searchIconCstm
            } ${isMobile && classes.headerIconsCartMobile}`}
          />
        </Badge>
      )}
    </>
  )
}

export default CartItemsBadge
