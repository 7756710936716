import axios from "axios"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import React, { useState, useRef } from "react"

import {
  Box,
  fade,
  Grid,
  Paper,
  Hidden,
  Popover,
  InputBase,
  IconButton,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core"

import CategoryBg from "../../../static/images/mainBg.jpg"
import BookImage from "../../../static/images/book-image.png"
import AuthorImage from "../../../static/images/partners/slider2.jpg"
import CategoryImg from "../../../static/images/Icons/categoryImage.svg"
import SearchIconCstm from "../../../static/images/Icons/searchIcon.svg"

const Serach = () => {
  const classes = useStyle()
  const { t, i18n } = useTranslation()
  const searchBtn = useRef(null)
  const [searchResults, setSearchResults] = useState({})
  const [searchLoading, setSearchLoading] = useState(false)
  const [anchorElSearch, setAnchorElSearch] = useState(null)
  const [searchData, setSearchData] = useState({
    enterdText: "",
  })

  const handleChangeSearch = prop => event => {
    setSearchData({ ...searchData, [prop]: event.target.value })
  }

  const handleCloseSearchPopover = () => {
    setAnchorElSearch(null)
  }

  const handleSearchSubmit = e => {
    e.preventDefault()
    setSearchLoading(true)
    if (searchData.enterdText) {
      axios
        .get(`${process.env.GATSBY_API_URL}/search-data/${searchData.enterdText}`, {
          headers: {
            "Accept-Language": i18n.language,
          },
        })
        .then(res => {
          setSearchLoading(false)
          setSearchResults(res.data)
          setAnchorElSearch(searchBtn.current)
        })
    } else {
      setSearchLoading(false)
      alert(t("errors.pleaseFillTheFiels"))
    }
  }

  const handleSearchClick = e => {
    // e.preventDefault()
    setSearchLoading(true)
    if (searchData.enterdText) {
      axios
        .get(`${process.env.GATSBY_API_URL}/search-data/${searchData.enterdText}`, {
          headers: {
            "Accept-Language": i18n.language,
          },
        })
        .then(res => {
          setSearchLoading(false)
          setSearchResults(res.data)
          setAnchorElSearch(searchBtn.current)
        })
    } else {
      setSearchLoading(false)
      alert(t("errors.pleaseFillTheFiels"))
    }
  }

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <form onSubmit={handleSearchSubmit} className={classes.headerSearchForm}>
          <Paper component="div" className={classes.headerSearchPaper} ref={searchBtn}>
            <InputBase
              className={classes.inputSearch}
              placeholder={t("header.searchLebel")}
              inputProps={{ "aria-label": t("header.searchLebel") }}
              onChange={handleChangeSearch("enterdText")}
            />
            {!searchLoading ? (
              <>
                <IconButton id="iconButtonForSearch" className={classes.iconButton} onClick={() => handleSearchClick()}>
                  <img src={SearchIconCstm} alt="SearchIcon" className={classes.searchIconCstm} />
                </IconButton>
              </>
            ) : (
              <CircularProgress
                style={{
                  padding: 8,
                  margin: 3,
                }}
              />
            )}
          </Paper>
        </form>
        <Popover
          open={Boolean(anchorElSearch)}
          anchorEl={anchorElSearch}
          onClose={handleCloseSearchPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              margin: "0 auto",
              maxWidth: 1280,
              width: "100%",
              maxHeight: "80vh",
            },
          }}
        >
          <Paper component="div">
            {Object.keys(searchResults).length > 0 ? (
              Object.keys(searchResults.books).length > 0 ||
              Object.keys(searchResults.authors).length > 0 ||
              Object.keys(searchResults.publishers).length > 0 ||
              Object.keys(searchResults.readers).length > 0 ||
              Object.keys(searchResults.categories).length > 0 ? (
                <>
                  {/*
                    Books
                  */}
                  {Object.keys(searchResults.books).length > 0 && (
                    <Paper elevation={0} component="div" className={classes.popoverSearchContent}>
                      <Grid container>
                        <Grid item sm={12}>
                          <Typography component="h4" className={classes.sectionTitleForSearch}>
                            {t("book.books")}
                          </Typography>
                        </Grid>
                        {searchResults.books.filter(item => item.havePaper).map(item => (
                          <Grid item sm={3} key={item.id} className={classes.bookGridForSearch}>
                            <Paper elevation={0} component="div" className={classes.bookInnerDivforSearch}>
                              <Link
                                to={`/${i18n.language}/books/${item.id}`}
                                onClick={() => setAnchorElSearch(!searchBtn.current)}
                              >
                                <img
                                  alt="single Book"
                                  src={item.image ? `${process.env.GATSBY_API_URL}${item.image.url}` : BookImage}
                                  className={classes.bookImageForSearch}
                                />
                                <Typography>{item.title}</Typography>
                              </Link>
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  )}
                  {/*
                    Authors
                  */}
                  {Object.keys(searchResults.authors).length > 0 && (
                    <Paper elevation={0} component="div" className={classes.popoverSearchContent}>
                      <Grid container spacing={4}>
                        <Grid item sm={12}>
                          <Typography component="h4" className={classes.sectionTitleForSearch}>
                            {t("author.authors")}
                          </Typography>
                        </Grid>
                        {searchResults.authors.map(item => (
                          <Grid item sm={3} key={item.id} className={classes.AuthorItem}>
                            <Link
                              to={`/${i18n.language}/authors/${item.id}`}
                              onClick={() => setAnchorElSearch(!searchBtn.current)}
                            >
                              <Box className={`${classes.AuthorItemBox} AuthorItemBox `}>
                                <img
                                  p={3}
                                  src={item.image ? `${process.env.GATSBY_API_URL}${item.image.url}` : AuthorImage}
                                  alt={item.firstName}
                                />
                                <Typography variant="h5" component="p">
                                  {item.firstName}
                                </Typography>
                              </Box>
                            </Link>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  )}
                  {/*
                    Publishers
                  */}
                  {Object.keys(searchResults.publishers).length > 0 && (
                    <Paper elevation={0} component="div" className={classes.popoverSearchContent}>
                      <Grid container spacing={4}>
                        <Grid item sm={12}>
                          <Typography component="h4" className={classes.sectionTitleForSearch}>
                            {t("book.publishers")}
                          </Typography>
                        </Grid>
                        {searchResults.publishers.map(item => (
                          <Grid item sm={3} key={item.id} className={classes.publisherItem}>
                            <Link
                              to={`/${i18n.language}/publishers/${item.id}`}
                              onClick={() => setAnchorElSearch(!searchBtn.current)}
                            >
                              <Box className={`${classes.publisherItemBox} publisherItemBox `}>
                                <img
                                  p={3}
                                  src={item.image ? `${process.env.GATSBY_API_URL}${item.image.url}` : CategoryBg}
                                  alt={item.name}
                                />
                                <Typography variant="h5" component="p">
                                  {item.name}
                                </Typography>
                              </Box>
                            </Link>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  )}
                  {/*
                    Categories
                  */}
                  {Object.keys(searchResults.categories).length > 0 && (
                    <Paper elevation={0} component="div" className={classes.popoverSearchContent}>
                      <Grid container spacing={4}>
                        <Grid item sm={12}>
                          <Typography component="h4" className={classes.sectionTitleForSearch}>
                            {t("admin.categories")}
                          </Typography>
                        </Grid>
                        {searchResults.categories.map(item => (
                          <Grid item sm={3} key={item.id} className={classes.categoryGridItem}>
                            <Link
                              to={`/${i18n.language}/categories/${item.id}`}
                              className={classes.categoryLink}
                              onClick={() => setAnchorElSearch(!searchBtn.current)}
                            >
                              <Box p={1} bgcolor="rgb(0, 62, 150)" className={classes.categoryBoxes}>
                                <img
                                  p={3}
                                  src={item.image ? `${process.env.GATSBY_API_URL}${item.image.url}` : CategoryImg}
                                  alt={item.title}
                                />
                                <Typography variant="h5" component="p">
                                  {item.title}
                                </Typography>
                              </Box>
                            </Link>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  )}
                  {/*
                    Readers
                  */}
                  {Object.keys(searchResults.readers).length > 0 && (
                    <Paper component="div" className={classes.popoverSearchContent}>
                      <Typography>{t("searchResults.serachForReaders")}</Typography>
                    </Paper>
                  )}
                </>
              ) : (
                <Typography className={classes.nothingFoundForSearch}>{t("searchResults.noThing")}</Typography>
              )
            ) : (
              <Typography className={classes.nothingFoundForSearch}>{t("searchResults.noThingSearch")}</Typography>
            )}
          </Paper>
        </Popover>
      </Hidden>
      <Hidden only={["xl", "lg", "md"]}>
        <Box className={`${classes.search} ${classes.searchMobile}`}>
          <form onSubmit={handleSearchSubmit}>
            <Paper component="div" className={classes.headerSearchMobile} ref={searchBtn}>
              <InputBase
                className={classes.inputSearch}
                placeholder={t("header.searchLebel")}
                inputProps={{ "aria-label": t("header.searchLebel") }}
                onChange={handleChangeSearch("enterdText")}
              />
              {!searchLoading ? (
                <IconButton className={classes.iconButton} onClick={() => handleSearchClick()}>
                  <img src={SearchIconCstm} alt="SearchIcon" className={classes.searchIconCstm} />
                </IconButton>
              ) : (
                <CircularProgress className={classes.iconButton} />
              )}
            </Paper>
          </form>
          <Popover
            open={Boolean(anchorElSearch)}
            anchorEl={anchorElSearch}
            onClose={handleCloseSearchPopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: { margin: "0 auto", width: "100%" },
            }}
          >
            <Paper component="div">
              <IconButton onClick={() => setAnchorElSearch(false)}>
                <Typography>X</Typography>
              </IconButton>
              {Object.keys(searchResults).length > 0 ? (
                Object.keys(searchResults.books).length > 0 ||
                Object.keys(searchResults.authors).length > 0 ||
                Object.keys(searchResults.publishers).length > 0 ||
                Object.keys(searchResults.readers).length > 0 ||
                Object.keys(searchResults.categories).length > 0 ? (
                  <>
                    {/*
                      Books
                    */}
                    {Object.keys(searchResults.books).length > 0 && (
                      <Paper elevation={0} component="div" className={classes.popoverSearchContent}>
                        <Grid container>
                          <Grid item sm={12}>
                            <Typography component="h4" className={classes.sectionTitleForSearch}>
                              {t("book.books")}
                            </Typography>
                          </Grid>
                          {searchResults.books.map(item => (
                            <Grid item sm={3} key={item.id} className={classes.bookGridForSearch}>
                              <Paper elevation={0} component="div" className={classes.bookInnerDivforSearch}>
                                <Link
                                  to={`/${i18n.language}/books/${item.id}`}
                                  onClick={() => setAnchorElSearch(!searchBtn.current)}
                                >
                                  <img
                                    alt="single Book"
                                    src={item.image ? `${process.env.GATSBY_API_URL}${item.image.url}` : BookImage}
                                    className={classes.bookImageForSearch}
                                  />
                                  <Typography>{item.title}</Typography>
                                </Link>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      </Paper>
                    )}
                    {/*
                      Authors
                    */}
                    {Object.keys(searchResults.authors).length > 0 && (
                      <Paper elevation={0} component="div" className={classes.popoverSearchContent}>
                        <Grid container spacing={4} direction="column">
                          <Grid item sm={12}>
                            <Typography component="h4" className={classes.sectionTitleForSearch}>
                              {t("author.authors")}
                            </Typography>
                          </Grid>
                          <Grid item sm={12}>
                            {searchResults.authors.map(item => (
                              <Grid item sm={3} key={item.id} className={classes.AuthorItem}>
                                <Link
                                  to={`/${i18n.language}/authors/${item.id}`}
                                  onClick={() => setAnchorElSearch(!searchBtn.current)}
                                >
                                  <Box className={`${classes.AuthorItemBox} AuthorItemBox `}>
                                    <img
                                      p={3}
                                      src={item.image ? `${process.env.GATSBY_API_URL}${item.image.url}` : AuthorImage}
                                      alt={item.firstName}
                                    />
                                    <Typography variant="h5" component="p">
                                      {item.firstName}
                                    </Typography>
                                  </Box>
                                </Link>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Paper>
                    )}
                    {/*
                      Publishers
                    */}
                    {Object.keys(searchResults.publishers).length > 0 && (
                      <Paper elevation={0} component="div" className={classes.popoverSearchContent}>
                        <Grid container spacing={4}>
                          <Grid item sm={12}>
                            <Typography component="h4" className={classes.sectionTitleForSearch}>
                              {t("book.publishers")}
                            </Typography>
                          </Grid>
                          {searchResults.publishers.map(item => (
                            <Grid item sm={3} key={item.id} className={classes.publisherItem}>
                              <Link
                                to={`/${i18n.language}/publishers/${item.id}`}
                                onClick={() => setAnchorElSearch(!searchBtn.current)}
                              >
                                <Box className={`${classes.publisherItemBox} publisherItemBox `}>
                                  <img
                                    p={3}
                                    src={item.image ? `${process.env.GATSBY_API_URL}${item.image.url}` : CategoryBg}
                                    alt={item.name}
                                  />
                                  <Typography variant="h5" component="p">
                                    {item.name}
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                          ))}
                        </Grid>
                      </Paper>
                    )}
                    {/*
                      Categories
                    */}
                    {Object.keys(searchResults.categories).length > 0 && (
                      <Paper elevation={0} component="div" className={classes.popoverSearchContent}>
                        <Grid container spacing={4}>
                          <Grid item sm={12}>
                            <Typography component="h4" className={classes.sectionTitleForSearch}>
                              {t("admin.categories")}
                            </Typography>
                          </Grid>
                          {searchResults.categories.map(item => (
                            <Grid item sm={3} key={item.id} className={classes.categoryGridItem}>
                              <Link
                                to={`/${i18n.language}/categories/${item.id}`}
                                className={classes.categoryLink}
                                onClick={() => setAnchorElSearch(!searchBtn.current)}
                              >
                                <Box p={1} bgcolor="rgb(0, 62, 150)" className={classes.categoryBoxes}>
                                  <img
                                    p={3}
                                    src={item.image ? `${process.env.GATSBY_API_URL}${item.image.url}` : CategoryImg}
                                    alt={item.title}
                                  />
                                  <Typography variant="h5" component="p">
                                    {item.title}
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                          ))}
                        </Grid>
                      </Paper>
                    )}
                    {/*
                      Readers
                    */}
                    {Object.keys(searchResults.readers).length > 0 && (
                      <Paper component="div" className={classes.popoverSearchContent}>
                        <Typography>{t("searchResults.serachForReaders")}</Typography>
                      </Paper>
                    )}
                  </>
                ) : (
                  <Typography className={classes.nothingFoundForSearch}>{t("searchResults.noThing")}</Typography>
                )
              ) : (
                <Typography className={classes.nothingFoundForSearch}>{t("searchResults.noThingSearch")}</Typography>
              )}
            </Paper>
          </Popover>
        </Box>
      </Hidden>
    </>
  )
}

export default Serach

const useStyle = makeStyles(theme => ({
  inputSearch: {
    flex: 1,
    "& input": {
      width: "100%",
      height: "100%",
      fontSize: ".8rem",
      paddingLeft: theme.spacing(1),
    },
  },
  iconButton: {
    padding: 10,
  },
  headerSearchMobile: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: 0,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  searchIconCstm: {
    margin: "initial !important",
  },
  searchMobile: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
  },
  popoverSearchContent: {
    padding: theme.spacing(2),
    maxWidth: 1280,
  },
  bookGridForSearch: {
    textAlign: "center",
    outlineWidth: 0,
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
    "& img": {
      maxWidth: "100%",
      textAlign: "center",
      border: "3px solid #000",
      borderRadius: 10,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  bookInnerDivforSearch: {
    maxWidth: "60%",
    textDecoration: "none",
    margin: "0 auto",
  },
  AuthorItem: {
    margin: "0 auto",
    textAlign: "center",
    outlineWidth: 0,
    marginBottom: "1.5rem",
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
      textAlign: "center",
      textDecoration: "none",
    },
    "& img": {
      textAlign: "center",
      maxWidth: 200,
    },
    "& p": {
      margin: "-5px auto 0",
      fontSize: "1rem",
      color: "#000",
      padding: "10px 0",
      maxWidth: 200,
    },
    "&& .AuthorItemBox p": {
      backgroundColor: "#e9e9e9",
    },
  },
  AuthorItemBox: {
    backgroundColor: "#003e96",
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
  publisherItem: {
    margin: "0 auto",
    textAlign: "center",
    outlineWidth: 0,
    marginBottom: "1.5rem",
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
      textAlign: "center",
      textDecoration: "none",
    },
    "& img": {
      textAlign: "center",
      maxWidth: 76,
    },
    "& p": {
      margin: "10px auto 0",
      fontSize: "1rem",
      color: "#fff",
    },
    "&:nth-child(odd) .publisherItemBox": {
      backgroundColor: "#003e96",
    },
    "&:nth-child(even) .publisherItemBox": {
      backgroundColor: "#018fff",
    },
  },
  categoryGridItem: {
    margin: "0 auto",
  },
  publisherItemBox: {
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
  sectionTitleForSearch: {
    fontWeight: "600",
    position: "relative",
    "&::after": {
      content: "''",
      width: "20px",
      height: 2,
      background: "black",
      position: "absolute",
      marginLeft: 20,
      bottom: 11,
    },
  },
  categoryLink: {
    textDecoration: "none",
  },
  categoryBoxes: {
    textAlign: "center",
    color: "white",
    paddingTop: 20,
    paddingBottom: 20,
    border: "solid 1px #707070",
    borderRadius: 10,
    "& p": {
      textShadow: "0 10px 6px rgba(0, 0, 0, 0.16)",
      fontSize: "1.3rem",
    },
    "& img": {
      maxWidth: "100px",
    },
  },
  nothingFoundForSearch: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  headerSearchForm: {
    width: "100%",
  },
  headerSearchPaper: {
    display: "flex",
    justifyContent: "space-between",
  },
}))
