import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"

import {
  makeStyles,
  Collapse,
  ListItem,
  ListItemText,
} from "@material-ui/core"

import MenuItem from "@material-ui/core/MenuItem"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

const windowGlobal = typeof window !== "undefined" && window

const useStyles = makeStyles({
  nested: {
    paddingLeft: 30
  }
})

const LanguageMenu = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [cllapseReleasesOpen, setCllapseReleasesOpen] = useState(false)

  const handleCllapseReleasesClick = () => {
    setCllapseReleasesOpen(!cllapseReleasesOpen)
  }

  if (windowGlobal) {
    if (!windowGlobal.localStorage.getItem("currencyKey")) {
      windowGlobal.localStorage.setItem("currencyKey", "USD")
    }
  }

  function handleChange(event) {
    setCurrency(event)
    if (windowGlobal) {
      setTimeout(() => windowGlobal.location.reload(true), 500)
      windowGlobal.localStorage.setItem("currencyKey", event)
      if (windowGlobal.localStorage.getItem("currencyKey") === "USD") {
        windowGlobal.sessionStorage.setItem("currencyRate", 1)
      }
    }
  }

  const [currency, setCurrency] = useState(
    windowGlobal && windowGlobal.localStorage.getItem("currencyKey")
  )

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/get-rate/TRY`)
      .then(res => {
        if (windowGlobal) {
          if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
            windowGlobal.sessionStorage.setItem("currencyRate", res.data)
          }
        }
      })
  }, [currency])

  return (
    <>
      <ListItem
        button
        onClick={handleCllapseReleasesClick}
        divider
        onChange={e => handleChange(e)}
        className={
          cllapseReleasesOpen
            ? `${classes.collapseMenu}`
            : `${classes.notCollapseMenu}`
        }
      >
        <ListItemText primary={t("header.currencyText")} />
        {cllapseReleasesOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={cllapseReleasesOpen}
        timeout="auto"
        unmountOnExit
      >
        <MenuItem onClick={() => handleChange('TRY')} className={classes.nested}>
          <span>TRY &#8378;</span>
        </MenuItem>
        <MenuItem onClick={() => handleChange('USD')} className={classes.nested}>
          <span>USD &#36;</span>
        </MenuItem>
      </Collapse>
    </>
  )
}

export default LanguageMenu
