import React from "react"
import { useTranslation } from "react-i18next"

import SigninForm from "../components/signin-form"

import {
  Button,
  makeStyles,
} from "@material-ui/core"

import AccountBoxIcon from "@material-ui/icons/AccountBox"

const useStyles = makeStyles(theme => ({
  dialogOpenBtn: {
    color: "white",
    fontSize: "1rem",
  },
  headerIcons: {
    color: "#fff",
    padding: "0.1rem",
    fontSize: "2rem !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
      width: "1.3rem",
    },
  },
}))

const Sign = () => {
  const [t] = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
        <Button
          className={classes.dialogOpenBtn}
          onClick={handleClickOpen}
          startIcon={
            <AccountBoxIcon
              className={`${classes.headerIcons} ${classes.searchIconCstm}`}
            />
          }
        >
          {t("signin.signinBtn")}
        </Button>
        <SigninForm isOpen={open} onClose={onClose} />
    </>
  )
}

export default Sign
