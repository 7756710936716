import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import {
  makeStyles,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  // IconButton,
  // InputBase,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@material-ui/core"

import Logo from "../../static/images/logo/logo_signup.svg"
import FooterLogo from "../../static/images/logo/footer_logo.svg"
import PaymentImage1 from "../../static/images/Icons/1payment-footer.svg"
import PaymentImage2 from "../../static/images/Icons/2payment-footer.svg"
import PaymentImage3 from "../../static/images/Icons/3payment-footer.svg"
import PaymentImage4 from "../../static/images/Icons/4payment-footer.svg"
import PaymentImage5 from "../../static/images/Icons/5payment-footer.svg"
// import facebookIcon from "../../static/images/Icons/1footer-social.svg"
// import instagramIcon from "../../static/images/Icons/2footer-social.svg"
// import telegramIcon from "../../static/images/Icons/3footer-social.svg"
// import twitterIcon from "../../static/images/Icons/4footer-social.svg"
// import whatsappIcon from "../../static/images/Icons/5footer-social.svg"
// import youtubeIcon from "../../static/images/Icons/6footer-social.svg"
import FooterCategory1 from "../../static/images/Icons/1CategoryIcons.svg"
import FooterCategory2 from "../../static/images/Icons/2CategoryIcons.svg"
import FooterCategory3 from "../../static/images/Icons/3CategoryIcons.svg"
import StoreIcons1 from "../../static/images/Icons/1StoreIcons.svg"
import StoreIcons2 from "../../static/images/Icons/2StoreIcons.svg"

import facebookIcon from "../../static/images/Icons/facebook.png"
import instagramIcon from "../../static/images/Icons/instagram.png"
import telegramIcon from "../../static/images/Icons/telegram.png"
import twitterIcon from "../../static/images/Icons/twitter.png"
import whatsappIcon from "../../static/images/Icons/whatsapp.png"
import youtubeIcon from "../../static/images/Icons/youtube.png"

const Footer = () => {
  const classes = useStyle()
  const [t, i18n] = useTranslation()

  function ListItemLink(props) {
    const { icon, primary, to } = props

    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <Link to={to} ref={ref} {...itemProps} />
        )),
      [to]
    )

    return (
      <ListItem component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText className={classes.drawerListItem} primary={primary} />
      </ListItem>
    )
  }

  ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string,
  }

  return (
    <>
      {/* {isVisilble && (
        <> */}
      <footer className={classes.footer}>
        <Container>
          <Grid container spacing={3}>
            <Grid
              item
              md
              sm={6}
              xs={12}
              className={`${classes.footerIconsBar} ${classes.footerGrid1}`}
            >
              <Typography
                variant="inherit"
                component="h2"
                className={`${classes.footerHeadings} ${classes.textShadowFooter}`}
              >
                {t("footer.websiteContent")}
              </Typography>
              <List
                className={`${classes.menuList} ${classes.textShadowFooter}`}
              >
                <ListItemLink
                  to={`/${i18n.language}/categories/9`}
                  primary={t("footer.mushafs")}
                />
                <ListItemLink
                  to={`/${i18n.language}/categories`}
                  primary={t("footer.categories")}
                />
                <ListItemLink
                  to={`/${i18n.language}/books-fairs`}
                  primary={t("footer.booksFairs")}
                />
                <ListItemLink
                  to={`/${i18n.language}/audios`}
                  primary={t("footer.audios")}
                />
                <ListItemLink
                  to={`/${i18n.language}/gallery-page`}
                  primary={t("footer.gallery")}
                />
                {/* <ListItemLink to="/#" primary={t("footer.videos")} /> */}
              </List>
              <Box
                display="flex"
                flexWrap="nowrap"
                className={classes.footerIconsPayments}
              >
                <Box m={1}>
                  <img
                    src={FooterLogo}
                    alt="Footer logo"
                    width="200"
                    height="46.56"
                    className={classes.footerAltLogo}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md
              sm={6}
              xs={12}
              className={`${classes.footerIconsBar} ${classes.footerGrid2}`}
            >
              <Typography
                variant="inherit"
                component="h2"
                className={`${classes.footerHeadings} ${classes.textShadowFooter}`}
              >
                {t("footer.importantLinks")}
              </Typography>
              <List
                className={`${classes.menuList} ${classes.textShadowFooter}`}
              >
                <ListItemLink
                  to={`/${i18n.language}/books`}
                  primary={t("footer.booksMenu")}
                />
                <ListItemLink
                  to={`/${i18n.language}/get-brochure`}
                  primary={t("footer.getBrochure")}
                />
                <ListItemLink
                  to={`/${i18n.language}/publishers`}
                  primary={t("footer.publishers")}
                />
                <ListItemLink
                  to={`/${i18n.language}/your-thoughts`}
                  primary={t("footer.yourThoughts")}
                />
                <ListItemLink
                  to={`/${i18n.language}/feedback`}
                  primary={t("footer.complaintsSuggestions")}
                />
              </List>
              <Box
                display="flex"
                flexWrap="nowrap"
                className={classes.footerIconsPayments}
              >
                <Box m={1}>
                  <img src={PaymentImage1} alt="visa payment" width="32" height="20" />
                </Box>
                <Box m={1}>
                  <img src={PaymentImage2} alt="wu payment" width="21" height="9" />
                </Box>
                <Box m={1}>
                  <img src={PaymentImage3} alt="mastercard 2 payment" width="27" height="21" />
                </Box>
                <Box m={1}>
                  <img src={PaymentImage4} alt="mastercard payment" width="27" height="21" />
                </Box>
                <Box m={1}>
                  <img src={PaymentImage5} alt="whatsapp payment" width="23" height="23" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md
              sm={6}
              xs={12}
              className={`${classes.footerIconsBar} ${classes.footerGrid3}`}
            >
              <Typography
                variant="inherit"
                component="h2"
                className={`${classes.footerHeadings} ${classes.textShadowFooter}`}
              >
                {t("footer.aboutWebsite")}
              </Typography>
              <List
                className={`${classes.menuList} ${classes.textShadowFooter}`}
              >
                <ListItemLink
                  to={`/${i18n.language}/cookies-policy`}
                  primary={t("pages.cookiesPolicy")}
                />
                <ListItemLink
                  to={`/${i18n.language}/about-us`}
                  primary={t("footer.aboutGawthani")}
                />
                <ListItemLink to={`/${i18n.language}/policy`} primary={t("footer.usagePolicy")} />
              </List>
              <Typography
                variant="inherit"
                component="h2"
                className={`${classes.footerHeadings} ${classes.textShadowFooter}`}
              >
                {t("footer.contactUs")}
              </Typography>
              <Typography
                variant="inherit"
                component="div"
                className={`${classes.footerContactInfo} ${classes.textShadowFooter}`}
              >
                {t("footer.address")}
              </Typography>
              <Typography
                variant="inherit"
                component="div"
                className={`${classes.footerContactInfo} ${classes.textShadowFooter} ${classes.footerContactInfoLinks}`}
              >
                <a href="tel:00905418983688">00905418983688</a>
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                className={`${classes.footerContactInfo} ${classes.textShadowFooter} ${classes.footerContactInfoLinks}`}
              >
                <a href="mailto:gwthani@gmail.com">info@gwthani.com</a>
              </Typography>
              <Box
                display="flex"
                flexWrap="nowrap"
                className={classes.footerIconsPayments}
              >
                <Box m={1} className={`${classes.footerSocialIcons} ${classes.footerSocialWhatsapp}`}>
                  <a href="https://wa.me/905418983688">
                    <img src={whatsappIcon} alt="whatsapp" />
                  </a>
                </Box>
                <Box m={1} className={`${classes.footerSocialIcons} ${classes.footerSocialYoutube}`}>
                  <a href="https://www.youtube.com/channel/UCTX6KlOv5JqAYZRNQPz6myw">
                    <img src={youtubeIcon} alt="youtube" />
                  </a>
                </Box>
                <Box m={1} className={`${classes.footerSocialIcons} ${classes.footerSocialFacebook}`}>
                  <a href="https://www.facebook.com/Gwthani/">
                    <img src={facebookIcon} alt="facebook" />
                  </a>
                </Box>
                <Box m={1} className={`${classes.footerSocialIcons} ${classes.footerSocialInstagram}`}>
                  <a href="https://www.instagram.com/Gwthani/">
                    <img src={instagramIcon} alt="instagram" />
                  </a>
                </Box>
                <Box m={1} className={`${classes.footerSocialIcons} ${classes.footerSocialTelegram}`}>
                  <a href="https://t.me/daralgawthani">
                    <img src={telegramIcon} alt="telegram" />
                  </a>
                </Box>
                <Box m={1} className={`${classes.footerSocialIcons} ${classes.footerSocialTwitter}`}>
                  <a href="https://twitter.com/Gwthani/">
                    <img src={twitterIcon} alt="twitter" />
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md
              sm={6}
              xs={12}
              className={`${classes.footerLogo} ${classes.footerGrid4}`}
            >
              <img src={Logo} alt="logo" />
              <Box display="flex" justifyContent="center">
                <Box className={classes.footerStoreIcons}>
                  <a href="https://play.google.com/store/apps/details?id=com.arafas.kitabhadef">
                    <img src={StoreIcons1} alt="Google Play" width="160" height="58" />
                  </a>
                </Box>
                <Box className={classes.footerStoreIcons}>
                  <a href="https://apps.apple.com/us/app/كتابي-الهادف/id1565685455">
                    <img src={StoreIcons2} alt="App Store" width="160" height="58" />
                  </a>
                </Box>
              </Box>
              <Typography variant="h6" component="h4">
                {t("footer.purposefulBook")}
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                className={classes.footerBoxesRoot}
              >
                <Box p={1} m={1} className={classes.footerBoxes}>
                  <img src={FooterCategory1} alt="Foliar" />
                  {t("footer.foliar")}
                </Box>
                <Box p={1} m={1} className={classes.footerBoxes}>
                  <img src={FooterCategory2} alt="Electronic" />
                  {t("footer.electronic")}
                </Box>
                <Box p={1} m={1} className={classes.footerBoxes}>
                  <img src={FooterCategory3} alt="Vocal" />
                  {t("footer.vocal")}
                </Box>
              </Box>
              <Typography
                variant="inherit"
                component="div"
                className={`${classes.footerNewsLatterTitle}`}
              >
                {t("footer.newsletter")}
              </Typography>
              <Typography
                variant="inherit"
                component="div"
                className={`${classes.footerNewsLatterSubTitle}`}
              >
                {t("footer.subscribeDescription")}
              </Typography>
              <form>
                <FormControl
                  className={(classes.margin, classes.newlatterInputControl)}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-password"
                    placeholder={t("footer.emailPlaseholder")}
                    className={`${classes.newlatterInput} newlatterInput`}
                    type="email"
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          aria-label="toggle password visibility"
                          edge="end"
                          className={`${classes.newlatterSubmit} newlatterSubmit`}
                        >
                          {t("footer.submitBtn")}
                        </Button>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Container>
      </footer>
      <div className={classes.footerBottom}>
        <Container>
          <Typography>
            {t("footer.copysights")} {new Date().getFullYear()},{" "}
            {/* {t("footer.poweredBy")} */}
            {/* {` `} */}
            {/* <a href="https://www.webotak.com"> */}{/* Arafas Apps */}{/* </a> */}
          </Typography>
        </Container>{" "}
      </div>
    </>
    //   )}
    // </>
  )
}

export default Footer

const useStyle = makeStyles(theme => ({
  footer: {
    backgroundColor: "#003e96",
    paddingTop: 36,
    paddingBottom: 36,
  },
  footerBottom: {
    backgroundColor: "#018fff",
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 16,
  },
  footerLogo: {
    textAlign: "center",
    color: "#ffffff",
    "& img": {
      width: 100,
      margin: "initial",
    },
  },
  footerHeadings: {
    color: "#018fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  textShadowFooter: {
    textShadow: "0px 10px 6px rgba(0, 0, 0, 0.16)",
  },
  menuList: {
    "&>a": {
      color: "#fff",
      paddingTop: "initial",
      paddingBottom: "initial",
    },
    "&>a span": {
      fontSize: 16,
    },
    "&>a:hover span": {
      textDecoration: "underline",
    },
  },
  footerContactInfo: {
    color: "#fff",
    marginTop: 12,
    paddingLeft: 16,
    paddingRight: 16,
    "& a": {
      color: "#fff",
      textDecoration: "none",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
  },
  footerContactInfoLinks: {
    marginTop: 8,
  },
  footerBoxes: {
    "& img": {
      height: 40,
      width: 40,
      margin: "0 auto 5px",
    },
  },
  footerNewsLatterTitle: {
    fontSize: 22,
    lineHeight: "24px",
    marginBottom: 8,
    marginTop: 8,
  },
  footerNewsLatterSubTitle: {
    fontSize: 14,
    lineHeight: "24px",
  },
  newlatterSubmit: {
    backgroundColor: "#018fff",
    borderLeft: "1px solid #fff",
    borderBottomLeftRadius: "initial",
    borderTopLeftRadius: "initial",
    height: 46,
  },
  newlatterInputControl: {
    marginTop: 12,
  },
  footerIconsBar: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      marginTop: "3rem",
    },
  },
  footerIconsPayments: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: 0,
    },
  },
  footerAltLogo: {
    // width: "70%",
  },
  footerGrid1: {
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
  footerGrid2: {
    [theme.breakpoints.down("md")]: {
      order: 3,
    },
  },
  footerGrid3: {
    [theme.breakpoints.down("md")]: {
      order: 4,
    },
  },
  footerGrid4: {
    [theme.breakpoints.down("md")]: {
      order: 1,
    },
  },
  footerStoreIcons: {
    margin: theme.spacing(1),
    "& img": {
      margin: "initial",
    },
  },
  footerSocialIcons: {
    // padding: "20px"
    "& img": {
      width: 24
    }
  }
}))