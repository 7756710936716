import React, { useEffect, useState } from "react"
import Select from "@material-ui/core/Select"
import { MenuItem, makeStyles } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import axios from "axios"

const windowGlobal = typeof window !== "undefined" && window

const useStyle = makeStyles(theme => ({
  currencySwitcher: {
    verticalAlign: "middle",
    marginLeft: 10,
  },
  icon: {
    fill: "#fff",
  },
  currencySwitcherFirst: {
    paddingRight: theme.spacing(1),
    color: "#fff",
  },
  langSwitcherItem: {
    paddingTop: 3,
    paddingBottom: 3,
    "& .MuiSelect-select.MuiSelect-select": {
      paddingTop: "initial",
      paddingBottom: "initial",
    },
  },
  elevation8: {
    marginTop: 44,
  },
  headerMenuBtnsOpen: {
    background: "transparent",
  },
  headerMenuBtns: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 16,
    },
  },
  headerMenuBtnsClose: {
    color: "black",
  },
}))

const CurrencyMenu = props => {
  const classes = useStyle()
  const [currency, setCurrency] = useState("")

  useEffect(() => {
    setCurrency(windowGlobal && windowGlobal.localStorage.getItem("currencyKey"))
  }, [])

  if (windowGlobal) {
    if (!windowGlobal.localStorage.getItem("currencyKey")) {
      windowGlobal.localStorage.setItem("currencyKey", "USD")
    }
  }

  const handleChange = event => {
    setCurrency(event.target.value)
    if (windowGlobal) {
      setTimeout(() => windowGlobal.location.reload(true), 500)
      windowGlobal.localStorage.setItem("currencyKey", event.target.value)
      if (windowGlobal.localStorage.getItem("currencyKey") === "USD") {
        windowGlobal.sessionStorage.setItem("currencyRate", 1)
      }
    }
  }

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/get-rate/TRY`)
      .then(res => {
        if (windowGlobal) {
          if (windowGlobal.localStorage.getItem("currencyKey") === "USD") {
            windowGlobal.sessionStorage.setItem("currencyRate", res.data)
          }
        }
      })
  }, [currency])

  return (
    <>
      <Select
        className={`${classes.langSwitcherItem} langSwitcherItem`}
        value={currency}
        onChange={e => handleChange(e)}
        disableUnderline
        IconComponent={ExpandMoreIcon}
        inputProps={{
          name: "currency",
          classes: {
            icon: classes.icon,
          },
        }}
        renderValue={() => (
          <span
            className={`${classes.currencySwitcher} ${classes.currencySwitcherFirst} currencySwitcher`}
          >
            {currency}
          </span>
        )}
      >
        <MenuItem value={"TRY"}>
          <span>TRY</span>
        </MenuItem>
        <MenuItem value={"USD"}>
          <span>USD</span>
        </MenuItem>
      </Select>
    </>
  )
}

export default CurrencyMenu
