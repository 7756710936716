import axios from "axios"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useSnackbar } from "notistack"
import { ConfirmCodeModule } from "./auth"
import MuiAlert from "@material-ui/lab/Alert"
import { useTranslation } from "react-i18next"

import {
  Grid,
  Button,
  Dialog,
  TextField,
  makeStyles,
  ButtonGroup,
  FormControl,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from "@material-ui/core"

import CloseIcon from "@material-ui/icons/Close"
import FacebookIcon from "@material-ui/icons/Facebook"
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined"
import Unlock from "../../static/images/Icons/unlock-alt-solid.svg"

const SigninForm = ({ isOpen = false, onClose }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  const phoneRegexd = /^([0|\+[0-9]{1,5})?([0-9]{10})$/
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/

  const [loading, setLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [openChangePassModal, setOpenChangePassModal] = useState(false)
  const [openForgetPasswordModal2, setOpenForgetPassModal] = useState(false)
  const [openForgetPasswordModal, setOpenForgetPasswordModal] = useState(false)
  const [openAlertErrorForgotOnSubmit, setOpenAlertErrorForgotOnSubmit] = useState(false)
  const [verifyAccount, setVerifyAccount] = useState({
    openVerifyAccountModal: false,
    userJwt: "",
    userRole: "",
    userId: ""
  })
  const [confPassword, setConfPassword] = useState({
    confPasswordVal: "",
    showPassword: false,
  })
  const [signinValues, setSigninValues] = useState({
    userName: "",
    fullName: "",
    password: "",
    code: "",
    userIdFromResponse: "",
  })
  const [errorMassege, setErrorMassege] = useState({
    password: false,
    passwordText: "",
    username: false,
    usernameText: "",
    code: false,
    codeText: "",
  })

  const closeModal = () => {
    onClose()
  }
  const handleCloseModal = () => {
    closeModal()
  }
  const handleChangeConfPassword = prop => event => {
    setConfPassword({ ...confPassword, [prop]: event.target.value })
  }
  const handleChange = prop => event => {
    if (prop === "userName" && isSubmitted) {
      enterdUserName()
    }
    setSigninValues({ ...signinValues, [prop]: event.target.value })
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
  }
  const goToSignUp = () => {
    closeModal()
    setOpenForgetPassModal(false)
    navigate(`/${i18n.language}/signup`)
  }

  let email
  let phoneNo

  const enterdUserName = () => {
    if (emailRegExp.test(signinValues.userName)) {
      setErrorMassege({ ...errorMassege, username: false, usernameText: "" })
      email = signinValues.userName
      return true
    } else if (phoneRegexd.test(signinValues.userName)) {
      setErrorMassege({ ...errorMassege, username: false, usernameText: "" })
      phoneNo = signinValues.userName
      return true
    } else if (signinValues.userName) {
      setErrorMassege({
        ...errorMassege,
        username: true,
        usernameText: t("errors.invalidUserName"),
      })
      return false
    } else {
      setErrorMassege({
        ...errorMassege,
        username: true,
        usernameText: t("errors.requiredField"),
      })
      return false
    }
  }

  const enterdPaddwordMatch = () => {
    if (signinValues.password === confPassword.confPasswordVal) {
      if (!passwordRegex.test(signinValues.password)) {
        setErrorMassege({
          ...errorMassege,
          password: true,
          passwordText: t("errors.passwordEntriesWrong"),
        })
        return false
      } else {
        setErrorMassege({ ...errorMassege, password: false, passwordText: "" })
        return true
      }
    } else {
      setErrorMassege({
        ...errorMassege,
        password: true,
        passwordText: t("errors.passwordEntriesWrong"),
      })
      return false
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setIsSubmitted(true)

    if (enterdUserName()) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/auth/local`,
          {
            identifier: signinValues.userName,
            password: signinValues.password,
          },
          {
            headers: {
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(res => {
          setLoading(false)
          setDisabledBtn(false)

          if (res.data.user.confirmed === false || res.data.user.confirmed === null) {
            setVerifyAccount({ ...verifyAccount, openVerifyAccountModal: true, userJwt: res.data.jwt, userRole: res.data.user.role.name, userId: res.data.user.id })
            closeModal()
            enqueueSnackbar(t("helpers.you-must-confirm-your-account"), { variant: "warning", autoHideDuration: 3000 })
            axios
              .get(`${process.env.GATSBY_API_URL}/resend-account-confirm-code`, {
                headers: {
                  Authorization: `Bearer ${res.data.jwt}`,
                  "Accept-Language": i18n.language,
                },
              }).then(() => {
                enqueueSnackbar(t("helpers.code-sent-to-your-mail-tel"), { variant: "info", autoHideDuration: 3000 })
              })
          } else if (res.data.user.confirmed === true) {
            enqueueSnackbar(t("signin.successfullyLoggedIn"), { variant: "success", autoHideDuration: 3000 })
            setTimeout(() => closeModal(), 1500)
            setTimeout(() => windowGlobal.location.reload(true), 1800)
            windowGlobal.sessionStorage.setItem("jwtCode", res.data.jwt)
            windowGlobal.sessionStorage.setItem("userRl", res.data.user.role.name)
            windowGlobal.sessionStorage.setItem("userId", res.data.user.id)
          }
        })
        .catch(err => {
          setLoading(false)
          setDisabledBtn(false)
          enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
        })
    } else {
      setLoading(false)
      enqueueSnackbar(t("signin.checkFields"), { variant: "error", autoHideDuration: 3000 })
      // setErrorMassege({
      //   ...errorMassege,
      //   username: true,
      //   usernameText: t("errors.requiredField"),
      // })
    }
  }

  const handleCloseVerifyCodeModal = () => {
    setVerifyAccount({ ...verifyAccount, openVerifyAccountModal: false })
  }

  const handleSendForgetPassCode = e => {
    e.preventDefault()
    setLoading(true)
    setIsSubmitted(true)
    setDisabledBtn(true)

    if (enterdUserName()) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/forgot-password`,
          {
            email: email ? email : "",
            phone: phoneNo ? phoneNo : "",
          },
          {
            headers: {
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(() => {
          setDisabledBtn(false)
          setLoading(false)
          setTimeout(() => setOpenForgetPassModal(false), 1000)
          setTimeout(() => setOpenForgetPasswordModal(true), 1000)
        })
        .catch(err => {
          setLoading(false)
          setDisabledBtn(false)
          enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
        })
    } else {
      setLoading(false)
      setDisabledBtn(false)
      enqueueSnackbar(t("errors.errorForgotOnSubmit"), { variant: "error", autoHideDuration: 3000 })
    }
  }

  const handleForgetPasswordSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setIsSubmitted(true)
    setDisabledBtn(true)

    if (enterdUserName()) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/forgot-password-code`,
          {
            code: signinValues.code,
          },
          {
            headers: {
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(res => {
          setDisabledBtn(false)
          setLoading(false)
          setSigninValues({ userIdFromResponse: res.data.userID })
          setTimeout(() => setOpenForgetPasswordModal(false), 1000)
          setTimeout(() => setOpenChangePassModal(true), 1000)
        })
        .catch(err => {
          setLoading(false)
          setDisabledBtn(false)
          enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
        })
    } else {
      setLoading(false)
      setDisabledBtn(false)
      enqueueSnackbar(t("errors.errorForgotOnSubmit"), { variant: "error", autoHideDuration: 3000 })
    }
  }

  const handleChangePassSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setIsSubmitted(true)
    setDisabledBtn(true)

    if (enterdPaddwordMatch()) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/forgot-password-form`,
          {
            newPassword: signinValues.password,
            confirmNewPassword: confPassword.confPasswordVal,
            userID: signinValues.userIdFromResponse,
          },
          {
            headers: {
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(() => {
          setDisabledBtn(false)
          setLoading(false)
          setTimeout(() => setOpenChangePassModal(false), 1500)
          enqueueSnackbar(t("signin.passwordChangedSuccessfully"), { variant: "success", autoHideDuration: 3000 })
        })
        .catch(err => {
          setLoading(false)
          setDisabledBtn(false)
          enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
        })
    } else {
      setLoading(false)
      setDisabledBtn(false)
      enqueueSnackbar(t("errors.errorForgotOnSubmit"), { variant: "error", autoHideDuration: 3000 })
    }
  }

  const openForgetModal = () => {
    handleCloseModal()
    setOpenForgetPassModal(true)
  }
  const handleCloseGetCodeModal = () => {
    setOpenForgetPassModal(false)
  }
  const handleCloseFrogetPasswordModal = () => {
    setOpenForgetPasswordModal(false)
  }
  const handleCloseChangePassModal = () => {
    setOpenChangePassModal(false)
  }

  const resendCode = () => {
    axios.post(`${process.env.GATSBY_API_URL}/resend-account-confirm-code`, {
      headers: {
        "Accept-Language": i18n.language,
      },
    })
  }

  const loginButtons = (
    <ButtonGroup
      width="100%"
      fullWidth={true}
      color="primary"
      aria-label="outlined primary button group"
      className={classes.margin}
    >
      <Button
        href={`${process.env.GATSBY_API_URL}/connect/facebook/redirect`}
        variant="contained"
        color="primary"
        className={classes.signinWithFacebookBtn}
        startIcon={<FacebookIcon />}
      >
        Facebook
      </Button>
      <Button
        href={`${process.env.GATSBY_API_URL}/connect/google/redirect`}
        variant="contained"
        className={classes.signinWithFacebookBtn}
        startIcon={
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="google"
            className={`${classes.googleSvgIson} svg-inline--fa fa-google fa-w-16`}
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 488 512"
          >
            <path
              fill="currentColor"
              d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
            ></path>
          </svg>
        }
        color="secondary"
      >
        Google
      </Button>
    </ButtonGroup>
  )

  return (
    <>
      {/* Sing in form */}
      <Dialog
        open={isOpen}
        onClose={handleCloseModal}
        className={classes.dialogForm}
        aria-labelledby="form-dialog-title"
      >
        <DialogActions className={classes.dialogMainActions}>
          <Button onClick={handleCloseModal} color="primary" className={`${classes.dialogActionsBtns} closeButton`}>
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent className={classes.dialogMainContent}>
          <PersonIcon className={classes.dialogSigninIcon} />
          <DialogContentText>{t("signin.Title")}</DialogContentText>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <FormControl fullWidth className={`${classes.marginForForms} ${classes.formsLabel}`}>
                  <TextField
                    // required
                    id="userName"
                    type="text"
                    label={t("signup.username")}
                    variant="outlined"
                    onChange={handleChange("userName")}
                    error={errorMassege.username}
                    helperText={errorMassege.usernameText}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item md={12} sm={12} xs={12}>
                <FormControl fullWidth className={`${classes.marginForForms} ${classes.formsLabel}`}>
                  <TextField
                    required
                    id="password"
                    name="password"
                    label={t("signup.password")}
                    type="password"
                    variant="outlined"
                    onChange={handleChange("password")}
                    className={classes.marginForForms}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Button
                  disableFocusRipple
                  disableRipple
                  style={{ textTransform: "none" }}
                  className={`${classes.button} button`}
                  variant="text"
                  color="primary"
                  onClick={openForgetModal}
                >
                  {t("signin.newPassword")}
                </Button>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid container spacing={2} justify="space-between" className={classes.marginForForms}>
              <Grid item xs={12}>
                <ButtonGroup
                  // width="100%"
                  fullWidth={true}
                  // color="primary"
                  // aria-label="outlined primary button group"
                  // className={classes.margin}
                  disableElevation
                  variant="contained"
                  color="primary"
                >
                  <Button
                    // variant="contained"
                    // color="primary"
                    type="submit"
                    fullWidth={true}
                    disabled={disabledBtn}
                    // loading={loading}
                    className={`${classes.button} ${classes.buttonSignupSite} ${classes.signinBtns} ${classes.signinGroupBtns} button`}
                  >
                    {loading && <CircularProgress size={26} />}
                    {!loading && t("signin.signinBtn")}
                  </Button>
                  {/* </Grid>
              <Grid item xs={6}> */}
                  <Button
                    width="100%"
                    fullWidth={true}
                    // variant="contained"
                    // color="primary"
                    className={`${classes.signinBtns2} ${classes.signinGroupBtns}`}
                    onClick={goToSignUp}
                  >
                    {t("signin.signupBtn")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
          {loginButtons}
        </DialogContent>
      </Dialog>

      {/* Froget Password */}
      <Dialog
        open={openForgetPasswordModal2}
        onClose={handleCloseGetCodeModal}
        className={classes.dialogForm}
        aria-labelledby="form-dialog-title"
      >
        <DialogActions className={classes.dialogMainActions}>
          <Button
            onClick={handleCloseGetCodeModal}
            color="primary"
            className={`${classes.dialogActionsBtns} closeButton`}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent className={classes.dialogMainContent}>
          <img src={Unlock} alt="Unlock" className={classes.dialogSigninIcon} />
          <DialogContentText>{t("signin.changePassowrd")}</DialogContentText>
          <form onSubmit={handleSendForgetPassCode}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <FormControl fullWidth className={`${classes.marginForForms} ${classes.formsLabel}`}>
                  <TextField
                    // required
                    id="userName"
                    type="text"
                    label={t("signup.username")}
                    variant="outlined"
                    onChange={handleChange("userName")}
                    error={errorMassege.username}
                    helperText={errorMassege.usernameText}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="space-between" className={classes.marginForForms}>
              <Grid item xs={12}>
                <ButtonGroup width="100%" fullWidth={true} color="primary" aria-label="outlined primary button group">
                  <Button onClick={goToSignUp}>{t("signin.signupBtn")}</Button>
                  <Button
                    disabled={disabledBtn}
                    variant="contained"
                    type="submit"
                    // loading={loading.loading}
                  >
                    {loading && <CircularProgress size={26} />}
                    {!loading && t("signin.getCode")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {/* Confirm Forget Password Code */}
      <Dialog
        open={openForgetPasswordModal}
        onClose={handleCloseFrogetPasswordModal}
        className={classes.dialogForm}
        aria-labelledby="form-dialog-title"
      >
        <DialogActions className={classes.dialogMainActions}>
          <Button
            onClick={handleCloseFrogetPasswordModal}
            color="primary"
            className={`${classes.dialogActionsBtns} closeButton`}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent className={classes.dialogMainContent}>
          <img src={Unlock} alt="Unlock" className={classes.dialogSigninIcon} />
          <DialogContentText>{t("signin.verifyCode")}</DialogContentText>
          <form onSubmit={handleForgetPasswordSubmit}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <FormControl fullWidth className={`${classes.marginForForms} ${classes.formsLabel}`}>
                  <TextField
                    required
                    id="code"
                    type="text"
                    label={t("signin.code")}
                    variant="outlined"
                    onChange={handleChange("code")}
                    error={errorMassege.code}
                    helperText={errorMassege.codeText}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} sm={12} xs={12}></Grid>
            </Grid>
            <Grid container spacing={2} justify="space-between" className={classes.marginForForms}>
              <Grid item xs={12}>
                <ButtonGroup width="100%" fullWidth={true} color="primary" aria-label="outlined primary button group">
                  <Button onClick={resendCode}>{t("signin.resendCode")}</Button>
                  <Button
                    disabled={disabledBtn}
                    type="submit"
                    variant="contained"
                    // loading={loading.loading}
                  >
                    {loading && <CircularProgress size={26} />}
                    {!loading && t("signin.verifyCode")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {/* Change password Form */}
      <Dialog
        open={openChangePassModal}
        onClose={handleCloseChangePassModal}
        className={classes.dialogForm}
        aria-labelledby="form-dialog-title"
      >
        <DialogActions className={classes.dialogMainActions}>
          <Button
            onClick={handleCloseChangePassModal}
            color="primary"
            className={`${classes.dialogActionsBtns} closeButton`}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent className={classes.dialogMainContent}>
          <img src={Unlock} alt="Unlock" className={classes.dialogSigninIcon} />
          <DialogContentText>{t("signin.changePassowrd")}</DialogContentText>
          <form onSubmit={handleChangePassSubmit}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <FormControl className={`${classes.margin} ${classes.formConrolSignup}`} fullWidth>
                  <TextField
                    required
                    id="password"
                    name="password"
                    label={t("signup.password")}
                    variant="outlined"
                    type={confPassword.showPassword ? "text" : "password"}
                    error={errorMassege.password}
                    helperText={errorMassege.passwordText}
                    onChange={handleChange("password")}
                  />
                </FormControl>
                <FormControl className={`${classes.margin} ${classes.formConrolSignup}`} fullWidth>
                  <TextField
                    required
                    id="confPassword"
                    name="confPassword"
                    label={t("signup.passwordConfirm")}
                    variant="outlined"
                    type={confPassword.showPassword ? "text" : "password"}
                    error={errorMassege.password}
                    helperText={errorMassege.passwordText}
                    maxLength="5"
                    minLength="1"
                    onChange={handleChangeConfPassword("confPasswordVal")}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="space-between" className={classes.marginForForms}>
              <Grid item xs={12}>
                <ButtonGroup width="100%" fullWidth={true} color="primary" aria-label="outlined primary button group">
                  <Button onClick={goToSignUp}>{t("signin.signupBtn")}</Button>
                  <Button
                    disabled={disabledBtn}
                    variant="contained"
                    type="submit"
                    // loading={loading.loading}
                  >
                    {loading && <CircularProgress size={26} />}
                    {!loading && t("signin.changeNow")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {/* Confirm Not confirmd account */}
      <ConfirmCodeModule
        isLoading={loading}
        isBtnDisabled={disabledBtn}
        userData={{userId: verifyAccount.userId, userJwt: verifyAccount.userJwt, userRl: verifyAccount.userRole}}
        openVerifyAccountModal={verifyAccount.openVerifyAccountModal}
        closeVerifyAccountModal={handleCloseVerifyCodeModal}
        setVerifyAccount={setVerifyAccount}
        verifyAccount={verifyAccount}
      />
    </>
  )
}

export default SigninForm

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginForForms: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogSigninIcon: {
    width: "6rem",
    height: "6rem",
    fontSize: "100%",
    fill: "#003e96",
  },
  dialogMainContent: {
    textAlign: "center",
    width: "24rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dialogMainActions: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  signinBtns: {
    backgroundColor: "#003e96",
    border: "2px solid #003e96",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
    "&:hover": {
      color: "#003e96",
      backgroundColor: "#fff",
    },
  },
  signinBtns2: {
    borderRadius: 4,
    color: "#003e96",
    backgroundColor: "#fff",
    border: "2px solid #003e96",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
    "&:hover": {
      color: "#fff",
      backgroundColor: "#003e96",
    },
  },
  signinGroupBtns: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  formsLabel: {
    "& .MuiInputLabel-root": {
      [theme.breakpoints.down("sm")]: {
        fontSize: ".7rem",
      },
    },
    "& .MuiInputBase-input": {
      [theme.breakpoints.down("sm")]: {
        padding: "14.5px 14px",
      },
    },
  },
  googleSvgIson: {
    width: 18,
    height: 18,
  },
}))

const windowGlobal = typeof window !== "undefined" && window

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
