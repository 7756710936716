import React, { useState, useEffect } from "react"
import { withTrans } from "../i18n/withTrans"
import { useTranslation } from "react-i18next"
import { SnackbarProvider } from "notistack"
import { create } from "jss"
import rtl from "jss-rtl"
import axios from "axios"
// import { Provider } from "../context/context"
// import AppProvider from "../context/AppProvider"
import SiteContext from "../context/siteContext"
import { red } from "@material-ui/core/colors";

import { CssBaseline, createMuiTheme, ThemeProvider, StylesProvider, jssPreset } from "@material-ui/core"

import "./layout.css"
import { Header } from "./header"
import Footer from "./footer"

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const themeRtl = createMuiTheme({
  direction: "rtl",
  // palette: {
  //   primary: {
  //     main: "#505050",
  //   },
  //   text: {},
  //   // secondary: {
  //   //   main: "#35a393",
  //   // },
  //   error: {
  //     main: red.A400,
  //   },
  //   background: {
  //     default: "#fff",
  //   },
  // },
  // TODO: change font family on site
  // typography: {
  //   fontFamily: "Tajawal, Arial",
  // },
  typography: {
    fontFamily: "Cairo, Arial",
  },
  ".MuiContainer-root": {
    color: "red !important",
  },
})

const themeLtr = createMuiTheme({
  // palette: {
  //   primary: {
  //     main: "#505050",
  //   },
  //   text: {},
  //   secondary: {
  //     main: "#35a393",
  //   },
  //   error: {
  //     main: red.A400,
  //   },
  //   background: {
  //     default: "#fff",
  //   },
  // },
  // typography: {
  //   fontFamily: "Tajawal, Arial",
  // },
  // typography: {
  //   fontFamily: "Cairo, Arial",
  // },
  ".MuiContainer-root": {
    color: "red !important",
  },
})

const Layout = ({ children }) => {
  const { i18n } = useTranslation()
  const [cartItemContentLenght, setCartItemContentLenght] = useState(0)
  const [isActiveCart, setIsActiveCart] = useState(false)
  const value = {
    isActiveCart,
    setIsActiveCart,
    cartItemContentLenght,
    setCartItemContentLenght,
  }

  useEffect(() => {
    if (documentGlobal) {
      documentGlobal.body.scrollTop = 0
      documentGlobal.documentElement.scrollTop = 0
    }
    // if (windowGlobal) {
    //   windowGlobal.scroll({
    //     top: 0,
    //     left: 0,
    //     behavior: "smooth",
    //   })
    // }
  }, [])

  // let jwtForProfile
  // if (windowGlobal) {
  //   jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  // }

  // useEffect(() => {
  //   if (jwtForProfile) {
  //     axios
  //       .get(`${process.env.GATSBY_API_URL}/get-cart-items-by-type/paper`, {
  //         headers: {
  //           Authorization: `Bearer ${jwtForProfile}`,
  //           "Accept-Language": i18n.language,
  //         },
  //       })
  //       .then(res => {
  //         setCartItemContent(res.data.items.length)
  //         windowGlobal.localStorage.setItem(
  //           "cartStatus",
  //           res.data.items.length ? `${true}` : `${false}`
  //         )
  //         // setGlobalStates({
  //         //   isActiveCart: windowGlobal.localStorage.getItem("cartStatus"),
  //         // })
  //       })
  //   }
  // }, [i18n, jwtForProfile])

  useEffect(() => {
    axios.get(`${process.env.GATSBY_API_URL}/get-rate/TRY`).then(res => {
      if (windowGlobal) {
        if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
          windowGlobal.sessionStorage.setItem("currencyRate", res.data)
        }
      }
    })
  }, [])

  const allDataContent = (
    <SiteContext.Provider value={value}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: i18n.language === "ar" ? "right" : "left",
        }}
        disableWindowBlurListener={true}
        // classes={i18n.language === "ar" ? "rightSnackbars" : "leftSnackbars"}
      >
        {i18n.language === "ar" ? (
          <ThemeProvider theme={themeRtl}>
            <StylesProvider jss={jss}>
              <CssBaseline />
              <Header cartItemsCount={cartItemContentLenght} />
              <main>{children}</main>
              <Footer />
            </StylesProvider>
          </ThemeProvider>
        ) : (
          <>
            <ThemeProvider theme={themeLtr}>
              <CssBaseline />
              <Header cartItemsCount={cartItemContentLenght} />
              <main>{children}</main>
              <Footer />
            </ThemeProvider>
          </>
        )}
      </SnackbarProvider>
    </SiteContext.Provider>
  )

  return (
    <>
      {children.key === "/videos/" || children.key === `/${i18n.language}/mobile-about-us` ? (
        <main>{children}</main>
      ) : (
        <>{allDataContent}</>
      )}
    </>
  )
}

export default withTrans(Layout)
