import React, { useState } from "react"
import MenuItem from "@material-ui/core/MenuItem"
import {
  makeStyles,
  Collapse,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"

import arLogo from "../../images/ar.png"
import enLogo from "../../images/en.png"
import trLogo from "../../images/tr.png"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles({
  selectFocus: {
    paddingLeft: 16,
    "&:focus": {
      background: "transparent",
    },
  },
  langSwitcherImg: {
    width: 38,
    verticalAlign: "middle",
    margin: 0,
    marginRight: 10,
    "& + span": {
      marginLeft: 10,
    },
  },
  nested: {
    paddingLeft: 30
  }
})

const LanguageMenu = props => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const [cllapseReleasesOpen, setCllapseReleasesOpen] = useState(false)

  function handleChange(event, index) {
    i18n.changeLanguage(event)
  }

  const handleCllapseReleasesClick = () => {
    setCllapseReleasesOpen(!cllapseReleasesOpen)
  }

  return (
    <>
      <ListItem
        button
        onClick={handleCllapseReleasesClick}
        divider
        className={
          cllapseReleasesOpen
            ? `${classes.collapseMenu}`
            : `${classes.notCollapseMenu}`
        }
      >
        <ListItemText primary={t("header.language")} />
        {cllapseReleasesOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={cllapseReleasesOpen}
        timeout="auto"
        unmountOnExit
      >
        <MenuItem onClick={() => handleChange('ar')} className={classes.nested}>
          <img
            src={arLogo}
            alt="AR"
            className={`${classes.langSwitcherImg} langSwitcherImg`}
          />
          {t("menu.arabic")}
        </MenuItem>
        <MenuItem onClick={() => handleChange('en')} className={classes.nested}>
          <img
            src={enLogo}
            alt="EN"
            className={`${classes.langSwitcherImg} langSwitcherImg`}
          />
          {t("menu.english")}
        </MenuItem>
        <MenuItem onClick={() => handleChange('tr')} className={classes.nested}>
          <img
            src={trLogo}
            alt="TR"
            className={`${classes.langSwitcherImg} langSwitcherImg`}
          />
          {t("menu.turkish")}
        </MenuItem>
      </Collapse>
    </>
  )
}

export default LanguageMenu
