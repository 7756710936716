import React, { useState, useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import axios from "axios"
import { useTranslation } from "react-i18next"
import MuiAlert from "@material-ui/lab/Alert"
import { SnackbarProvider } from "notistack"
import SiteContext from "../../context/siteContext"
import Search from "./search"

import {
  makeStyles,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Container,
  Box,
  Hidden,
  Button,
  Popover,
  Toolbar,
  AppBar,
  fade,
  Snackbar,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core"

import Sigin from "../signin"
import LanguageMenu from "./LangSwitcher"
import SelectLanguage from "./SelectLanguage"
import CurrencyMenu from "./currencySwitcher"
import SelectCurrency from "./selectCurrency"
import SigninForm from "../signin-form"
import CartItemsBadge from "./cartItemsBadge"

import HeaderBackground from "../../../static/images/headerBg-min.png"
import Logo from "../../../static/images/logo/logo_signup.svg"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MenuIcon from "@material-ui/icons/Menu"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import CloseIcon from "@material-ui/icons/Close"
import TwitterIcon from "@material-ui/icons/Twitter"
import TelegramIcon from "@material-ui/icons/Telegram"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import YouTubeIcon from "@material-ui/icons/YouTube"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import CartItems from "../../../static/images/Icons/cartItems.svg"
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

const windowGlobal = typeof window !== "undefined" && window

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function ButtonLink(props) {
  const { icon, primary, to } = props
  const classes = useStyle()

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to]
  )

  return (
    <Button
      component={renderLink}
      className={`${classes.headerMenu} ${classes.headerMenuBtnsHover} ${classes.headerBtn}`}
      activeClassName={classes.activeMenu}
      startIcon={icon}
    >
      {primary}
    </Button>
  )
}

ButtonLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

const useStyle = makeStyles(theme => ({
  header: {
    background: "#003e96",
    backgroundImage: `url(${HeaderBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  headerLogo: {
    textAlign: "center",
    "& img": {
      maxWidth: 100,
      height: "auto",
      margin: "25px auto 18px",
    },
    "& h2": {
      color: "white",
      margin: "25px 0 12px",
      fontSize: "1.3rem",
    },
  },
  headerSearch: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: "1.3rem !important",
    marginBottom: 0,
  },
  headerSectionBottom: {
    position: "relative",
  },
  headerIcons: {
    color: "#fff",
    padding: "0.1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
      width: "1.3rem",
    },
  },
  headerIconsNotification: {
    [theme.breakpoints.down("md")]: {
      marginTop: ".2rem",
    },
  },
  desktopMenu: {
    justifyContent: "space-evenly",
    display: "flex",
    width: "100%",
    "& *": {
      color: "#fff",
      fontSize: "1rem",
      fontWeight: "600",
      [theme.breakpoints.down("md")]: {
        fontSize: ".8rem",
      },
    },
  },
  headerMenuBtns: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 16,
    },
  },
  headerMenuBtnsHover: {
    "&:hover": {
      background: "#003e96",
    },
  },
  activeMenu: {
    background: "#003e96",
    color: "#fff",
    "&:hover": {
      background: "#003e96 !important",
    },
  },
  headerMenuBtnsOpen: {
    background: "#003e96",
    color: "red",
  },
  headerMenuBtnsClose: {
    color: "black",
  },
  menuAppBar: {
    backgroundColor: "#018fff",
  },
  mobileLogo: {
    flexGrow: 1,
    margin: 5,
    maxWidth: 50,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  mobileLogoDiv: {
    "& img": {
      display: "inline-flex",
    },
  },
  appBarFlex: {
    display: "flex",
    position: "relative",
  },
  headerPopoversNotificationDesktop: {
    borderBottom: "1px solid #003e96",
    "&:last-child": {
      borderBottom: "0",
    },
  },
  headerIconsMobileDrawer: {
    fontSize: "2rem",
    padding: 2,
  },
  firstBoxeMobileDrawer: {
    margin: "16px auto",
  },
  drawerPaper: {
    width: 250,
    "& div.MuiInputBase-root": {
      width: "100%",
    },
    "& img": {},
  },
  searchIconCstm: {
    margin: "initial !important",
  },
  headerBtn: {
    color: "#fff",
    fontSize: "1rem",
  },
  headerIconsNotificationParent: {
    paddingTop: 6,
    paddingBottom: 6,
    "& svg": {
      fontSize: "1.8rem",
      width: "1.8rem",
    },
  },
  langMenu: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  headerSocial: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  headerSection1: {
    marginBottom: "0.9rem",
  },
  headerSection3: {
    marginBottom: "0.9rem",
    "& .MuiButtonBase-root:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
  headerIconsMyAccount: {
    padding: "0.1rem",
    fontSize: "2rem !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
      width: "1.3rem",
    },
  },
  myAccount: {
    "& .MuiButtonBase-root": {
      padding: "3px 8px",
    },
  },
  cartItemsGrid: {
    height: 37,
  },
  headerBoxNotification: {
    [theme.breakpoints.down("md")]: {
      marginTop: ".06rem",
    },
    "& .MuiPopover-paper": {
      maxWidth: 300,
    },
  },
  notificationsPopOver: {
    "& .MuiPopover-paper": {
      maxWidth: 300,
    },
  },
  collapsedMenu: {
    "& .MuiButtonBase-root:last-child": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  collapseMenu: {
    borderBottom: 0,
  },
  menuSelectLang: {
    width: "100%",
  },
  myAccountMobile: {
    padding: "11px 12px 12px",
    position: "relative",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(1),
    width: "auto",
    "&>button": {
      padding: "initial",
    },
  },
  headerIconsMobile: {
    color: "#fff",
  },
  headerIconsCartMobile: {
    width: "1.4rem",
    height: "1.4rem",
  },
  appBarCartMobile: {
    textAlign: "center",
    padding: "11px 13px 12px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(1),
  },
  headerCartBtnMobile: {
    padding: 0,
  },
  headerChangeLang: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  nested: {
    paddingLeft: 30,
  },
}))

const Header = () => {
  const classes = useStyle()
  const { t, i18n } = useTranslation()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [notifications, setNotifications] = useState()
  const [signFormOpen, setSignFormOpen] = useState(false)
  const [openNotLoggedIn, setOpenNotLoggedIn] = useState(false)
  const [anchorElReleases, setAnchorElReleases] = useState(null)
  const [cllapseReleasesOpen, setCllapseReleasesOpen] = useState(false)
  const [anchorElNotification, setAnchorElNotification] = useState(null)

  const { isActiveCart, setIsActiveCart, cartItemContentLenght, setCartItemContentLenght } = useContext(SiteContext)

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }
  const handleCllapseReleasesClick = () => {
    setCllapseReleasesOpen(!cllapseReleasesOpen)
  }
  const handleReleasesClick = event => {
    setAnchorElReleases(event.currentTarget)
  }
  const handleReleasesClose = () => {
    setAnchorElReleases(null)
  }
  const handleCloseLoginSnack = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenNotLoggedIn(false)
  }
  const handleCartClick = event => {
    navigate(`/${i18n.language}/cart-items`)
  }
  const handleNotificationClick = event => {
    if (!jwtForProfile) {
      setOpenNotLoggedIn(true)
    } else {
      setAnchorElNotification(event.currentTarget)
    }
  }
  const handleNotificationClose = () => {
    setAnchorElNotification(null)
  }
  const habdleSignFormOpen = () => {
    setSignFormOpen(true)
  }
  const handleGoToAccount = () => {
    navigate(`/${i18n.language}/my-profile`)
  }

  let lclLang
  if (windowGlobal) {
    lclLang = windowGlobal.localStorage.getItem("i18nextLng").substring(0, 2)
  }

  if (windowGlobal) {
    windowGlobal.localStorage.setItem("i18nextLng", lclLang)
  }

  function ListItemLink(props) {
    const { icon, primary, to } = props

    const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
      [to]
    )

    return (
      <ListItem button component={renderLink} onClick={handleDrawerToggle} divider>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText className={classes.drawerListItem} primary={primary} />
      </ListItem>
    )
  }

  ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string,
  }

  const desktopMenu = (
    <Box className={classes.desktopMenu} justifyContent="center" alignItems="center">
      <ButtonLink
        to={`/`}
        // ${i18n.language}
        primary={t("header.homePage")}
        className={classes.headerMenuBtns}
      />
      <Button
        onClick={handleReleasesClick}
        aria-controls="releases-menu"
        aria-haspopup="true"
        className={
          Boolean(anchorElReleases)
            ? `${classes.headerMenuBtnsOpen} ${classes.headerMenuBtns} ${classes.headerMenuBtnsHover}`
            : `${classes.headerMenuBtnsClose} ${classes.headerMenuBtns} ${classes.headerMenuBtnsHover}`
        }
      >
        {t("header.releasesPage")}
        <ExpandMoreIcon className={`${classes.headerIcons} ${classes.headerIconsLang}`} />
      </Button>
      <Menu
        id="releases-menu"
        anchorEl={anchorElReleases}
        keepMounted
        open={Boolean(anchorElReleases)}
        onClose={handleReleasesClose}
        className={classes.subMenu}
        PaperProps={{
          style: {
            marginTop: 44,
            width: 200,
          },
        }}
      >
        <div>
          <MenuItem
            component={Link}
            to={`/${i18n.language}/books`}
            activeClassName={classes.activeMenu}
            onClick={() =>
              setTimeout(() => {
                handleReleasesClose()
              }, 100)
            }
          >
            {t("menu.books")}
          </MenuItem>
        </div>
        <div>
          <MenuItem
            component={Link}
            to={`/${i18n.language}/categories`}
            activeClassName={classes.activeMenu}
            onClick={() =>
              setTimeout(() => {
                handleReleasesClose()
              }, 100)
            }
          >
            {t("menu.categories")}
          </MenuItem>
        </div>
        {/* <div>
          <MenuItem
            component={Link}
            to={`/${i18n.language}/categories/المصاحف`}
            activeClassName={classes.activeMenu}
            onClick={() =>
              setTimeout(() => {
                handleReleasesClose()
              }, 100)
            }
          >
            {t("menu.mushafs")}
          </MenuItem>
        </div>
        <div>
          <MenuItem
            component={Link}
            to={`/${i18n.language}/categories/رسائل-جامعية`}
            activeClassName={classes.activeMenu}
            onClick={() => handleReleasesClose()}
          >
            {t("menu.message")}
          </MenuItem>
        </div>
        <div>
          <MenuItem
            component={Link}
            to={`/${i18n.language}/categories/الدراسات-القرآنية`}
            activeClassName={classes.activeMenu}
            onClick={() => handleReleasesClose()}
          >
            {t("menu.kuranStudy")}
          </MenuItem>
        </div>
        <div>
          <MenuItem
            component={Link}
            to={"/distribution"}
            activeClassName={classes.activeMenu}
            onClick={() => handleReleasesClose()}
          >
            {t("menu.distribution")}
          </MenuItem>
        </div> */}
      </Menu>
      <ButtonLink to={`/${i18n.language}/categories/13`} primary={t("header.translationsPage")} />
      <ButtonLink to={`/${i18n.language}/audios`} primary={t("header.audiosPage")} />
      <ButtonLink to={`/${i18n.language}/gallery-page`} primary={t("header.galleryPage")} />
      <ButtonLink to={`/${i18n.language}/about-us`} primary={t("header.aboutUsPage")} />
      <ButtonLink to={`/${i18n.language}/contact-us`} primary={t("header.contactUsPage")} />
      <ButtonLink to={`/${i18n.language}/get-brochure`} primary={t("header.getBrochurePage")} />
      <ButtonLink to={`/${i18n.language}/order-book`} primary={t("header.orderBookPage")} />
    </Box>
  )

  const drawer = (
    <div className={`${classes.drawerPaper}`}>
      <List className={classes.menuList}>
        <Button onClick={handleDrawerToggle} color="primary">
          <CloseIcon />
        </Button>
        <Box justifyContent="center" display="flex">
          <Box className={classes.firstBoxeMobileDrawer}>
            <IconButton
              href="https://twitter.com/Gwthani/"
              className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`}
            >
              <TwitterIcon className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`} />
            </IconButton>
            <IconButton
              href="https://t.me/daralgawthani"
              className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`}
            >
              <TelegramIcon className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`} />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/Gwthani/"
              className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`}
            >
              <InstagramIcon
                className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`}
              />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/Gwthani/"
              className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`}
            >
              <FacebookIcon className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`} />
            </IconButton>
            <IconButton
              href="https://www.youtube.com/channel/UCTX6KlOv5JqAYZRNQPz6myw"
              className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`}
            >
              <YouTubeIcon className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`} />
            </IconButton>
            <IconButton
              href="https://wa.me/905418983688"
              className={`${classes.headerIconsMobileDrawer} ${classes.headerIconsSocialMobileDrawer}`}
            >
              <WhatsAppIcon />
            </IconButton>
          </Box>
        </Box>
        <hr className={classes.drawerHr} />

        <ListItemLink to="/" primary={t("header.homePage")} />

        <ListItem
          button
          onClick={handleCllapseReleasesClick}
          divider
          className={cllapseReleasesOpen ? `${classes.collapseMenu}` : `${classes.notCollapseMenu}`}
        >
          <ListItemText primary={t("header.releasesPage")} />
          {cllapseReleasesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={cllapseReleasesOpen} timeout="auto" unmountOnExit>
          <List dense={true} component="div" disablePadding className={classes.collapsedMenu}>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              onClick={handleDrawerToggle}
              to={`/${i18n.language}/books`}
            >
              {t("menu.books")}
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              component={Link}
              onClick={handleDrawerToggle}
              to={`/${i18n.language}/categories`}
            >
              {t("menu.categories")}
            </ListItem>

            {/* <ListItem
              button
              className={classes.nested}
              component={Link}
              onClick={handleDrawerToggle}
              to={`/${i18n.language}/categories/الدراسات-القرآنية`}
            >
              {t("menu.kuranStudy")}
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              component={Link}
              onClick={handleDrawerToggle}
              to={"/distribution"}
            >
              {t("menu.distribution")}
            </ListItem> */}
          </List>
        </Collapse>

        <ListItemLink to={`/${i18n.language}/categories/الكتب-المترجمة`} primary={t("header.translationsPage")} />
        <ListItemLink to={`/${i18n.language}/audios`} primary={t("header.audiosPage")} />
        <ListItemLink to={`/${i18n.language}/gallery-page`} primary={t("header.galleryPage")} />
        <ListItemLink to={`/${i18n.language}/about-us`} primary={t("header.aboutUsPage")} />
        <ListItemLink to={`/${i18n.language}/contact-us`} primary={t("header.contactUsPage")} />
        <ListItemLink to={`/${i18n.language}/get-brochure`} primary={t("header.getBrochurePage")} />
        <ListItemLink to={`/${i18n.language}/order-book`} primary={t("header.orderBookPage")} />
        <SelectLanguage className={classes.menuSelectLang} />
        <SelectCurrency className={classes.langMenu} />
      </List>
    </div>
  )

  let jwtForHeader
  if (windowGlobal) {
    jwtForHeader = windowGlobal.sessionStorage.getItem("jwtCode")
  } else {
    jwtForHeader = false
  }
  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }

  useEffect(() => {
    if (jwtForProfile) {
      axios
        .get(`${process.env.GATSBY_API_URL}/notifications`, {
          headers: { Authorization: `Bearer ${jwtForProfile}` },
        })
        .then(res => {
          setNotifications(res.data)
        })
    }
  }, [jwtForProfile])

  const signFormClose = () => {
    setSignFormOpen(false)
  }

  // document.getElementById("iconButtonForSearch").addEventListener("click", function(event){
  //   event.preventDefault()
  // });

  useEffect(() => {
    if (jwtForProfile) {
      axios
        .get(`${process.env.GATSBY_API_URL}/get-cart-items-by-type/paper`, {
          headers: {
            Authorization: `Bearer ${jwtForProfile}`,
            "Accept-Language": i18n.language,
          },
        })
        .then(res => {
          windowGlobal.localStorage.setItem("cartStatus", res.data.items.length ? `${true}` : `${false}`)
          setIsActiveCart(res.data.items.length ? true : false)
          setCartItemContentLenght(res.data.items.length)
        })
    }
  }, [i18n, jwtForProfile])

  return (
    <>
      <header className={classes.header}>
        <SigninForm isOpen={signFormOpen} onClose={signFormClose} />
        <Container>
          <Grid container className={classes.headerGrid}>
            <Hidden only={["sm", "xs"]}>
              <Grid
                item
                xs={4}
                container
                direction="row"
                justify="space-evenly"
                alignItems="flex-end"
                className={`${classes.headerSections} ${classes.headerSection1} ${classes.headerSectionBottom}`}
              >
                <Grid item className={classes.headerSocial}>
                  <IconButton
                    href="https://twitter.com/Gwthani/"
                    className={`${classes.headerIcons} ${classes.headerIconsSocial}`}
                  >
                    <TwitterIcon className={`${classes.headerIcons} ${classes.headerIconsSocial}`} />
                  </IconButton>
                  <IconButton
                    href="https://t.me/daralgawthani"
                    className={`${classes.headerIcons} ${classes.headerIconsSocial}`}
                  >
                    <TelegramIcon className={`${classes.headerIcons} ${classes.headerIconsSocial}`} />
                  </IconButton>
                  <IconButton
                    href="https://www.instagram.com/Gwthani/"
                    className={`${classes.headerIcons} ${classes.headerIconsSocial}`}
                  >
                    <InstagramIcon className={`${classes.headerIcons} ${classes.headerIconsSocial}`} />
                  </IconButton>
                  <IconButton
                    href="https://www.facebook.com/Gwthani/"
                    className={`${classes.headerIcons} ${classes.headerIconsSocial}`}
                  >
                    <FacebookIcon className={`${classes.headerIcons} ${classes.headerIconsSocial}`} />
                  </IconButton>
                  <IconButton
                    href="https://www.youtube.com/channel/UCTX6KlOv5JqAYZRNQPz6myw"
                    className={`${classes.headerIcons} ${classes.headerIconsSocial}`}
                  >
                    <YouTubeIcon className={`${classes.headerIcons} ${classes.headerIconsSocial}`} />
                  </IconButton>
                  <IconButton
                    href="https://wa.me/905418983688"
                    className={`${classes.headerIcons} ${classes.headerIconsSocial}`}
                  >
                    <WhatsAppIcon className={`${classes.headerIcons} ${classes.headerIconsSocial}`} />
                  </IconButton>
                </Grid>
                <Grid item className={classes.headerChangeLang}>
                  <CurrencyMenu className={classes.langMenu} />
                </Grid>
                <Grid item>
                  <LanguageMenu className={classes.langMenu} />
                </Grid>
              </Grid>
              <Grid item xs={4} className={`${classes.headerSections} ${classes.headerSection2} ${classes.headerLogo}`}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Link to={`/`}>
                      <img src={Logo} alt="logo" />
                    </Link>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h2">{t("header.purposefulBook")}</Typography>
                    <div className={classes.headerSearch}>
                      <Search />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={4}
                container
                direction="row"
                justify="space-evenly"
                alignItems="flex-end"
                className={`${classes.headerSections} ${classes.headerSection3} ${classes.headerSectionBottom}`}
              >
                <Grid item>
                  {!jwtForHeader ? (
                    <Sigin />
                  ) : (
                    <div className={classes.myAccount}>
                      <ButtonLink
                        className={classes.headerBtn}
                        to={`/${i18n.language}/my-profile`}
                        primary={t("header.myAccountBtn")}
                        icon={<AccountBoxIcon className={`${classes.headerIcons} ${classes.headerIconsMyAccount}`} />}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item className={classes.cartItemsGrid}>
                  <div className={classes.myAccount}>
                    <ButtonLink
                      className={classes.headerBtn}
                      to={`/${i18n.language}/cart-items`}
                      primary={t("header.cartItems")}
                      icon={
                        <>
                          <CartItemsBadge isDisplay={isActiveCart} itemsCount={cartItemContentLenght} />
                          <img
                            src={CartItems}
                            alt={t("header.cartItems")}
                            style={{
                              display: `${isActiveCart ? "none" : "block"}`,
                            }}
                            className={`${classes.headerIcons} ${classes.headerIconsLang} ${classes.searchIconCstm}`}
                          />
                        </>
                      }
                    />
                  </div>
                </Grid>
                <Grid item className={classes.headerBoxNotification}>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleNotificationClick}
                    className={`${classes.headerBtn} ${classes.headerIconsNotificationParent}`}
                  >
                    <NotificationsNoneIcon className={`${classes.headerIcons} ${classes.headerIconsNotification}`} />
                  </IconButton>
                  <Popover
                    id="id"
                    anchorEl={anchorElNotification}
                    open={Boolean(anchorElNotification)}
                    onClose={handleNotificationClose}
                    className={classes.notificationsPopOver}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Box flexWrap="nowrap">
                      {notifications &&
                        notifications.map(item => (
                          <Box p={1} m={1} key={item.token} className={classes.headerPopoversNotificationDesktop}>
                            {item.content.message}
                          </Box>
                        ))}
                    </Box>
                  </Popover>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
        <AppBar position="static" className={classes.menuAppBar}>
          <Toolbar variant="dense">
            <Hidden only={["sm", "xs"]}>
              <Container className={desktopMenu ? classes.appBarBlock : classes.appBarFlex}>{desktopMenu}</Container>
            </Hidden>
            <Hidden only={["xl", "lg", "md"]}>
              <Box
                display="inline-grid"
                alignItems="center"
                css={{
                  width: "100%",
                  gridTemplateColumns: "auto auto auto auto auto",
                }}
              >
                <Box>
                  <IconButton
                    onClick={handleDrawerToggle}
                    className={classes.appBarIcons}
                    aria-label="open"
                    edge="start"
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <SwipeableDrawer
                    variant="temporary"
                    anchor="left"
                    open={drawerOpen}
                    onOpen={handleDrawerToggle}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    {drawer}
                  </SwipeableDrawer>
                </Box>
                <Box>
                  <Link to={`/`}>
                    <div className={classes.mobileLogoDiv}>
                      <img src={Logo} alt="logo" className={classes.mobileLogo} />
                    </div>
                  </Link>
                </Box>
                {!jwtForHeader && <Box p={3}></Box>}
                <Search />
                {!jwtForHeader ? (
                  <div className={classes.myAccountMobile}>
                    <IconButton onClick={() => habdleSignFormOpen()}>
                      <ExitToAppIcon className={`${classes.headerIconsMobile} `} />
                    </IconButton>
                  </div>
                ) : (
                  <>
                    <Box className={classes.appBarCartMobile}>
                      {/* 0----------------------------========== */}
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleCartClick}
                        className={`${classes.headerBtn} ${classes.headerCartBtnMobile}`}
                      >
                        <CartItemsBadge isDisplay={isActiveCart} itemsCount={cartItemContentLenght} />
                        <img
                          src={CartItems}
                          alt={t("header.cartItems")}
                          style={{ display: `${isActiveCart ? "none" : "block"}` }}
                          className={`${classes.headerIconsMobile} ${classes.headerIconsLang} ${classes.headerIconsCartMobile}`}
                        />
                      </IconButton>
                    </Box>
                    <Box className={classes.myAccountMobile}>
                      <IconButton onClick={handleGoToAccount}>
                        <AccountBoxIcon className={`${classes.headerIconsMobile} `} />
                      </IconButton>
                    </Box>
                  </>
                )}
              </Box>
            </Hidden>
          </Toolbar>
        </AppBar>
        <SnackbarProvider maxSnack={3}>
          <Snackbar open={openNotLoggedIn} autoHideDuration={6000} onClose={handleCloseLoginSnack}>
            <Alert onClose={handleCloseLoginSnack} severity="error">
              {t("book.signInToContinue")}
            </Alert>
          </Snackbar>
        </SnackbarProvider>
      </header>
    </>
  )
}

export { Header }