import React from "react";

// set the defaults
const SiteContext = React.createContext({
  isActiveCart: false,
  setIsActiveCart: () => {},
  cartItemContentLenght: 0,
  setCartItemContentLenght: () => {}
});

export default SiteContext;
