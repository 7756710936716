/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import i18next from "i18next"
import 'lazysizes'

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

// transfers sessionStorage from one tab to another
var sessionStorage_transfer = function (event) {
  if (!event) {
    event = windowGlobal.event
  } // ie suq
  if (!event.newValue) return // do nothing if no value to work with
  if (event.key === "getSessionStorage") {
    // another tab asked for the sessionStorage -> send it
    localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage))
    // the other tab should now have it, so we're done with it.
    localStorage.removeItem("sessionStorage") // <- could do short timeout as well.
  } else if (event.key === "sessionStorage" && !sessionStorage.length) {
    // another tab sent data <- get it
    var data = JSON.parse(event.newValue)
    for (var key in data) {
      sessionStorage.setItem(key, data[key])
    }
  }
}

// listen for changes to localStorage
if (windowGlobal.addEventListener) {
  windowGlobal.addEventListener("storage", sessionStorage_transfer, false)
} else {
  windowGlobal.attachEvent("onstorage", sessionStorage_transfer)
}

// Ask other tabs for session storage (this is ONLY to trigger event)
if (!sessionStorage.length) {
  localStorage.setItem("getSessionStorage", "foobar")
  localStorage.removeItem("getSessionStorage", "foobar")
}

export const onClientEntry = () => {
  windowGlobal.onload = () => {
    let test = documentGlobal.location.pathname
    if (
      test.substring(1, 3) === "ar" ||
      test.substring(1, 3) === "en" ||
      test.substring(1, 3) === "tr"
    ) {
      i18next.changeLanguage(test.substring(1, 3))
    }
  }
}

let browserLang =
  (windowGlobal && navigator.language) ||
  (windowGlobal && navigator.userLanguage)

// const localLang =
if (
  windowGlobal &&
  windowGlobal.localStorage.getItem("i18nextLng") &&
  windowGlobal.localStorage.getItem("i18nextLng").length === 2
) {
  windowGlobal.localStorage.getItem("i18nextLng")
} else if (browserLang.includes("ar")) {
  windowGlobal &&
    windowGlobal.localStorage.setItem("i18nextLng", "ar")
} else if (browserLang.includes("en")) {
  windowGlobal &&
  windowGlobal.localStorage.setItem("i18nextLng", "en")
} else if (browserLang.includes("tr")) {
  windowGlobal &&
  windowGlobal.localStorage.setItem("i18nextLng", "tr")
} else {
  windowGlobal &&
    windowGlobal.localStorage.setItem("i18nextLng", "ar")
}