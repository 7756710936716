import React, { useState } from "react"
import Select from "@material-ui/core/Select"
import { MenuItem, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import arLogo from "../../images/ar.png"
import enLogo from "../../images/en.png"
import trLogo from "../../images/tr.png"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const windowGlobal = typeof window !== "undefined" && window

const langImages = {
  en: enLogo,
  ar: arLogo,
  tr: trLogo,
}

const useStyle = makeStyles(theme => ({
  langSwitcherImg: {
    width: 38,
    verticalAlign: "middle",
    margin: 0,
    "& + span": {
      marginLeft: 10,
    },
  },
  icon: {
    fill: "#fff",
  },
  langSwitcherFirstImg: {
    paddingRight: theme.spacing(1),
  },
  // langSwitcherItem: {
  //   "& .MuiSelect-select.MuiSelect-select": {
  //     paddingTop: "initial",
  //     paddingBottom: "initial",
  //   },
  // },
  elevation8: {
    marginTop: 44,
  },
}))

const LanguageMenu = () => {
  const classes = useStyle()
  const { t, i18n } = useTranslation()

  let lclLang
  if (windowGlobal) {
    lclLang = windowGlobal.localStorage.getItem("i18nextLng").substring(0, 2)
  }

  if (windowGlobal) {
    windowGlobal.localStorage.setItem("i18nextLng", lclLang)
  }

  const [lang, setLang] = useState(
    windowGlobal && windowGlobal.localStorage.getItem("i18nextLng")
  )

  function handleChange(event) {
    i18n.changeLanguage(event.target.value)
    setLang(event.target.value)
    windowGlobal.localStorage.setItem("lclLng", event.target.value)
    // windowGlobal.location.reload()
  }

  function handleClickLang() {
    // setTimeout(() => {
    //   console.log("hell")
    //   // windowGlobal.location.reload()
    // }, 100);
  }

  return (
    <Select
      className={`${classes.langSwitcherItem} langSwitcherItem`}
      value={lang}
      onChange={e => handleChange(e)}
      disableUnderline
      IconComponent={ExpandMoreIcon}
      inputProps={{
        name: "language",
        classes: {
          icon: classes.icon,
        },
      }}
      renderValue={() => (
        <img
          src={langImages[lang]}
          alt={lang}
          width="46"
          height="24"
          className={`${classes.langSwitcherImg} ${classes.langSwitcherFirstImg} langSwitcherImg`}
        />
      )}
    >
      <MenuItem value={"ar"} onClick={handleClickLang}>
        <img
          src={arLogo}
          alt="ar"
          className={`${classes.langSwitcherImg} langSwitcherImg`}
        />
        <span> {t("menu.arabic")}</span>
      </MenuItem>
      <MenuItem value={"en"} onClick={handleClickLang}>
        <img
          src={enLogo}
          alt="en"
          className={`${classes.langSwitcherImg} langSwitcherImg`}
        />
        <span> {t("menu.english")}</span>
      </MenuItem>
      <MenuItem value={"tr"} onClick={handleClickLang}>
        <img
          src={trLogo}
          alt="tr"
          className={`${classes.langSwitcherImg} langSwitcherImg`}
        />
        <span> {t("menu.turkish")}</span>
      </MenuItem>
    </Select>
  )
}

export default LanguageMenu
