import React, { useState, useEffect } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"

import {
  Grid,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  ButtonGroup,
  DialogContent,
  DialogActions,
  CircularProgress,
  DialogContentText,
} from "@material-ui/core"

import CloseIcon from "@material-ui/icons/Close"
import Unlock from "../../../static/images/Icons/unlock-alt-solid.svg"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

function ConfirmCodeModule({
  userData = {},
  errorMassege = {},
  isLoading = false,
  isBtnDisabled = false,
  closeVerifyAccountModal,
  isAccountConfirmed = false,
  openVerifyAccountModal = false,
  setVerifyAccount,
  verifyAccount = {},
}) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { userId, userJwt, userRl } = userData

  const [disabledBtn, setDisabledBtn] = useState(isBtnDisabled)
  const [loadingConfirm, setLoadingConfirm] = useState(isLoading)
  const [verifyCodeValue, setVerifyCodeValue] = useState("")

  const handleChange = () => event => {
    setVerifyCodeValue(event.target.value)
  }

  async function handleGetCodeSubmit(e) {
    e.preventDefault()
    setLoadingConfirm(true)
    setDisabledBtn(true)

    await axios
      .post(
        `${process.env.GATSBY_API_URL}/account-confirm`,
        {
          code: verifyCodeValue,
        },
        {
          headers: {
            Authorization: `Bearer ${userJwt}`,
            "Accept-Language": i18n.language,
          },
        }
      )
      .then(res => {
        setDisabledBtn(false)
        setLoadingConfirm(false)
        // isAccountConfirmed(true)
        setTimeout(() => closeVerifyAccountModal(), 1200)
        windowGlobal.sessionStorage.setItem("userId", userId)
        windowGlobal.sessionStorage.setItem("userRl", userRl)
        windowGlobal.sessionStorage.setItem("jwtCode", userJwt)
        setTimeout(() => windowGlobal.location.reload(true), 1800)
        enqueueSnackbar(t("helpers.account-verified"), { variant: "success", autoHideDuration: 3000 })
      })
      .catch(err => {
        setDisabledBtn(false)
        setLoadingConfirm(false)
        enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
      })
  }

  const resendCode = () => {
    axios.get(`${process.env.GATSBY_API_URL}/resend-account-confirm-code`, {
      headers: {
        Authorization: `Bearer ${userJwt}`,
        "Accept-Language": i18n.language,
      },
    })
  }

  const closeTheDialog = (event, reason) => {
    reason !== "backdropClick"
      ? setVerifyAccount({ ...verifyAccount, openVerifyAccountModal: false })
      : setVerifyAccount({ ...verifyAccount, openVerifyAccountModal: true })
  }

  return (
    <Dialog
      open={openVerifyAccountModal}
      onClose={(event, reason) => closeTheDialog(event, reason)}
      disableEscapeKeyDown
      className={classes.dialogForm}
      aria-labelledby="form-dialog-title"
    >
      <DialogActions className={classes.dialogMainActions}>
        <Button
          onClick={closeVerifyAccountModal}
          color="primary"
          className={`${classes.dialogActionsBtns} closeButton`}
        >
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogContent className={classes.dialogMainContent}>
        <img src={Unlock} alt="Unlock" className={classes.dialogSigninIcon} />
        <DialogContentText>
          {t("signup.verifyAccount")}
          <br />{" "}
          <Typography component="span" style={{ color: "#000" }}>
            {t("helpers.you-must-confirm-your-account")}
          </Typography>
          <br />
          <Typography component="span" style={{ color: "#000" }}>
            {t("helpers.code-sent-to-your-mail-tel")}
          </Typography>
        </DialogContentText>
        <form onSubmit={handleGetCodeSubmit}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl fullWidth className={`${classes.marginForForms} ${classes.formsLabel}`}>
                <TextField
                  // required
                  id="code"
                  type="text"
                  label={t("signup.code")}
                  variant="outlined"
                  onChange={handleChange()}
                  error={errorMassege.code}
                  helperText={errorMassege.codeText}
                  inputProps={{
                    maxLength: "5",
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="space-between" className={classes.marginForForms}>
            <Grid item xs={12}>
              <ButtonGroup width="100%" fullWidth={true} color="primary" aria-label="outlined primary button group">
                <Button disabled={disabledBtn} onClick={handleGetCodeSubmit} variant="contained">
                  {loadingConfirm && <CircularProgress size={26} />}
                  {!loadingConfirm && t("signin.verifyCode")}
                </Button>
                <Button disabled={disabledBtn} onClick={resendCode}>
                  {loadingConfirm && <CircularProgress size={26} />}
                  {!loadingConfirm && t("signin.resendCode")}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}
export { ConfirmCodeModule }

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logoSvg: {
    maxWidth: 180,
  },
  textCenter: {
    textAlign: "center",
  },
  buttonSignupSite: {
    marginTop: 30,
    marginBottom: 30,
  },
  form: {
    "& .react-tel-input": {
      marginBottom: 15,
      direction: "ltr",
      flip: false,
      "& input": {
        width: "100%",
      },
      "& .react-tel-input .form-control": {
        width: "100% important",
      },
    },
  },
  footerBoxes: {
    "& img": {
      height: 41,
      width: 26,
      margin: "0 auto 5px",
    },
  },
  footerStoreIcons: {
    margin: theme.spacing(1),
    "& img": {
      margin: "initial",
      width: "112px",
    },
  },
  formConrolSignup: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input": {
      padding: "10px 12px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  createAccountBtn: {
    height: 40,
    borderRadius: 10,
  },
  logoTextSide: {
    "& .MuiTypography-root": {
      color: "#003e96",
      fontWeight: "bold !important",
    },
  },
  mainContainer: {
    marginTop: "2.5rem",
    marginBottom: "4rem",
  },
  createAccountTitle: {
    color: "#003e96",
    marginBottom: "1rem",
    fontWeight: "bold !important",
  },
  marginTopForForm: {
    marginTop: theme.spacing(1.5),
  },
  marginForForms: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogSigninIcon: {
    width: "6rem",
    height: "6rem",
    fontSize: "100%",
    fill: "#003e96",
  },
  dialogMainContent: {
    textAlign: "center",
    width: "24rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  googleSvgIson: {
    width: 18,
    height: 18,
  },
}))
