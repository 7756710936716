import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18next.use(LanguageDetector).init({
  // lng: "ar",
  fallbackLng: "ar",
  resources: {
    ar: {
      translations: require("../locales/ar/translations.json"),
    },
    en: {
      translations: require("../locales/en/translations.json"),
    },
    tr: {
      translations: require("../locales/tr/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
  supportedLngs: ["ar", "en", "tr"],
})

i18next.languages = ["ar", "en", "tr"]
export default i18next
