// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-old-js": () => import("./../../../src/pages/404-old.js" /* webpackChunkName: "component---src-pages-404-old-js" */),
  "component---src-pages-custom-order-paid-js": () => import("./../../../src/pages/custom-order-paid.js" /* webpackChunkName: "component---src-pages-custom-order-paid-js" */),
  "component---src-pages-custom-orders-js": () => import("./../../../src/pages/custom-orders.js" /* webpackChunkName: "component---src-pages-custom-orders-js" */),
  "component---src-pages-order-paid-js": () => import("./../../../src/pages/order-paid.js" /* webpackChunkName: "component---src-pages-order-paid-js" */),
  "component---src-pages-order-pay-failed-js": () => import("./../../../src/pages/order-pay-failed.js" /* webpackChunkName: "component---src-pages-order-pay-failed-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-admin-js": () => import("./../../../src/templates/admin.js" /* webpackChunkName: "component---src-templates-admin-js" */),
  "component---src-templates-audios-js": () => import("./../../../src/templates/audios.js" /* webpackChunkName: "component---src-templates-audios-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-authors-js": () => import("./../../../src/templates/authors.js" /* webpackChunkName: "component---src-templates-authors-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-books-fairs-js": () => import("./../../../src/templates/books-fairs.js" /* webpackChunkName: "component---src-templates-books-fairs-js" */),
  "component---src-templates-books-js": () => import("./../../../src/templates/books.js" /* webpackChunkName: "component---src-templates-books-js" */),
  "component---src-templates-cart-items-new-js": () => import("./../../../src/templates/cart-items-new.js" /* webpackChunkName: "component---src-templates-cart-items-new-js" */),
  "component---src-templates-cart-items-old-js": () => import("./../../../src/templates/cart-items-old.js" /* webpackChunkName: "component---src-templates-cart-items-old-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-checkout-js": () => import("./../../../src/templates/checkout.js" /* webpackChunkName: "component---src-templates-checkout-js" */),
  "component---src-templates-checkout-new-js": () => import("./../../../src/templates/checkout-new.js" /* webpackChunkName: "component---src-templates-checkout-new-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-cookies-policy-js": () => import("./../../../src/templates/cookies-policy.js" /* webpackChunkName: "component---src-templates-cookies-policy-js" */),
  "component---src-templates-feedback-js": () => import("./../../../src/templates/feedback.js" /* webpackChunkName: "component---src-templates-feedback-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-get-brochure-js": () => import("./../../../src/templates/get-brochure.js" /* webpackChunkName: "component---src-templates-get-brochure-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-login-redirect-js": () => import("./../../../src/templates/loginRedirect.js" /* webpackChunkName: "component---src-templates-login-redirect-js" */),
  "component---src-templates-mobile-about-us-js": () => import("./../../../src/templates/mobile-about-us.js" /* webpackChunkName: "component---src-templates-mobile-about-us-js" */),
  "component---src-templates-my-profile-js": () => import("./../../../src/templates/my-profile.js" /* webpackChunkName: "component---src-templates-my-profile-js" */),
  "component---src-templates-order-book-js": () => import("./../../../src/templates/order-book.js" /* webpackChunkName: "component---src-templates-order-book-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-publisher-js": () => import("./../../../src/templates/publisher.js" /* webpackChunkName: "component---src-templates-publisher-js" */),
  "component---src-templates-publishers-js": () => import("./../../../src/templates/publishers.js" /* webpackChunkName: "component---src-templates-publishers-js" */),
  "component---src-templates-signup-js": () => import("./../../../src/templates/signup.js" /* webpackChunkName: "component---src-templates-signup-js" */),
  "component---src-templates-your-thoughts-js": () => import("./../../../src/templates/your-thoughts.js" /* webpackChunkName: "component---src-templates-your-thoughts-js" */)
}

